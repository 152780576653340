import {
  Box,
  Button,
  ButtonBase,
  Divider,
  Grid,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import WeMoveHeader from "../../components/header/weMoveHeader";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useEffect, useState } from "react";
import RediractionMessageModal from "../../components/modals/rediractionMessageModal";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getMoveRequestById,
  updateMoveRequestById,
} from "../../redux/actions/move-request";
import { unwrapResult } from "@reduxjs/toolkit";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import WidgetsIcon from "@mui/icons-material/Widgets";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import Person4OutlinedIcon from "@mui/icons-material/Person4Outlined";
import ReactGA from "react-ga4";
import ContactSupport from "../../components/contactSupport";
import NavBar from "../../components/navbar/navBar";
import LogoHeader from "../../components/logo-header/logoHeader";

const howerButton = {
  width: "100%",
  display: "block",
  "&:hover .hover-text": {
    color: "#5858E0",
  },
  "&:hover .hover-background": {
    backgroundColor: "#FFFFFF",
    boxShadow: "10px 10px 5px -2px rgba(88, 88, 224, 0.3)",
  },
  "&:hover .icon-background": {
    backgroundColor: "#5858E0",
  },
  "&:hover .icon": {
    color: "#FFFFFF",
  },
};

const BookedMove = () => {
  const [url, setUrl] = useState(process.env.REACT_APP_URL || "");

  if (url === "https://stagingapi.wemoveai.com/") {
    ReactGA.send({
      hitType: "pageview",
      page: "/move-success/:moveRequestId",
      title: "MoveSuccess Staging",
    });
  }

  if (url === "https://productionapi.wemoveai.com/") {
    ReactGA.send({
      hitType: "pageview",
      page: "/move-success/:moveRequestId",
      title: "MoveSuccess Production",
    });
  }

  if (url === "https://turbotenantapi.wemoveai.com/") {
    ReactGA.send({
      hitType: "pageview",
      page: "/move-success/:moveRequestId",
      title: "MoveSuccess Turbotenant",
    });
  }

  if (url === "https://soldapi.wemoveai.com/") {
    ReactGA.send({
      hitType: "pageview",
      page: "/move-success/:moveRequestId",
      title: "MoveSuccess Sold",
    });
  }

  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const { moveRequestId } = useParams();
  const [moveRequest, setMoveRequest] = useState<any>(null);

  const [link, setLink] = useState("");
  const [isRedirectMessageModal, setIsRedirectMessageModal] = useState(false);

  const handleRediractionModal = (url: any) => {
    setLink(url);
    setIsRedirectMessageModal(true);
  };
  const [count, setCount] = useState(0);
  const fetchMoveRequestById = (moveRequestId: any) => {
    dispatch<any>(getMoveRequestById(moveRequestId))
      .then(unwrapResult)
      .then((thisMoveRequest: any) => {
        setMoveRequest(thisMoveRequest);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (moveRequestId) {
      fetchMoveRequestById(moveRequestId);
    }
  }, [moveRequestId]);
  useEffect(() => {
    if (moveRequest !== null && count === 0) {
      setCount((counter) => counter + 1);
      updateThisMoveRequest(
        {
          contract_signed: true,
        },
        moveRequest.id
      );
    }
  }, [moveRequest]);
  const handleNavigation = () => {
    setFinishModal(true);
    if (
      process.env.REACT_APP_TENANT === "krupp" ||
      process.env.REACT_APP_TENANT === "movebees" ||
      process.env.REACT_APP_TENANT === "summitmoving"
    ) {
      localStorage.clear();
    }
    let url = "";
    if (
      process.env.REACT_APP_TENANT === "krupp" ||
      process.env.REACT_APP_URL === "https://kruppmoving.wemoveai.com"
    ) {
      url = "https://kruppmoving.com";
    } else if (process.env.REACT_APP_TENANT === "movebees") {
      url = "https://movebees.com";
    } else if (process.env.REACT_APP_TENANT === "summitmoving") {
      url = "https://summitmoving.com";
    } else if (process.env.REACT_APP_TENANT === "rockstarspromoverspartial") {
      url = "https://rockstarpromovers.com";
    } else if (process.env.REACT_APP_TENANT === "dearmanmoving") {
      url = "https://dearmanmoving.com";
    } else {
      url = "/not-found";
    }
    const newWindow = window.open(`${url}`, "_blank");
    if (newWindow) {
      // The new window/tab was successfully created, now close the current window
      window.close();
    } else {
      // The new window/tab couldn't be created (possibly due to popup blockers)
      alert("Please allow popups for this website");
    }
  };
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [finishModal, setFinishModal] = useState(false);
  const updateThisMoveRequest = (body: any, moveReqId: number) => {
    setIsPageLoading(true);
    const params = {
      id: moveReqId,
      ...body,
    };
    dispatch<any>(updateMoveRequestById(params))
      .then(unwrapResult)
      .then((thisMoveRequest: any) => {
        console.log("contract marked");
        setTimeout(() => {
          setIsPageLoading(false);
        }, 1300);
      })
      .catch((err: any) => {
        console.log(err);
        setTimeout(() => {
          setIsPageLoading(false);
        }, 1300);
      });
  };
  return (
    <>
      {/* <NavBar moveRequestId={moveRequestId} /> */}
      <LogoHeader />
      <Modal
        open={finishModal}
        onClose={() => {
          setFinishModal(true);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            p: 3,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            color={"#5858E0"}
            fontSize={15}
            fontWeight={600}
            fontStyle={"italic"}
          >
            You have been redirected in a new window. Please close this window.
            Thanks.
          </Typography>
        </Box>
      </Modal>
      <Grid container>
        <Grid item xs={12} sm={8} md={5} mt={5} marginX={"auto"}>
          <Box
            p={4}
            display={"flex"}
            justifyContent={"center"}
            sx={{ background: theme.palette.primary.main }}
          >
            <Stack alignItems={"center"}>
              <CheckCircleIcon
                fontSize="large"
                sx={{ color: "#FFFFFF", width: 120, height: 120 }}
              />
              <Typography
                fontSize={16}
                textAlign={"center"}
                fontWeight={600}
                mt={2}
                color={
                  process.env.REACT_APP_TENANT === "movebees"
                    ? "#000000"
                    : "#FFFFFF"
                }
              >
                Thank you for submitting your information. We're currently
                analyzing the data and will be in touch soon to review the
                details of your estimate.
              </Typography>
            </Stack>
          </Box>

          <Stack p={3} alignItems="center">
            <Button
              onClick={() => {
                // navigate(`/ConsumerDashboard/${moveRequest.user.id}`);
                handleNavigation();
              }}
              sx={{
                width: 180,
                height: 45,
                backgroundColor: theme.palette.primary.main,
                color:
                  process.env.REACT_APP_TENANT === "movebees"
                    ? "#000000"
                    : "#FFFFFF",
                fontSize: 12,
                fontWeight: 550,
              }}
            >
              Continue
            </Button>
          </Stack>
        </Grid>
      </Grid>

      {isRedirectMessageModal && (
        <RediractionMessageModal
          isRedirectMessageModal={isRedirectMessageModal}
          setIsRedirectMessageModal={setIsRedirectMessageModal}
          link={link}
        />
      )}
    </>
  );
};

export default BookedMove;
