import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useTheme, useMediaQuery, Stack, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import logoCube from  "../../assets/logo/Group 15.png"
import logoWeMove from "../../assets/logo/WEMOVE.png"
import TurboTenantLogo from "../../assets/logo/turbotenant_logo.png"
import MoveBeesLogo from "../../assets/logo/MoveBees-Logo-VR-Yellow.jpg"
import KruppLogo from "../../assets/logo/Krupp.png"
import SummitMovingLogo from "../../assets/logo/SummitMoving.png"

const UploadCompletedModal = ({
  uploadCompletedModalVisible,
  setUploadCompletedModalVisible,
}: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const currentTenant = process.env.REACT_APP_TENANT || "";

  const handleClose = () => setUploadCompletedModalVisible(true);
    const navigate = useNavigate();
  return (
    <Modal
      open={uploadCompletedModalVisible}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90vw', sm: '80vw', md: '50vw', lg: '40vw' },
          height: { xs: '80vh', md: 'auto' },
          overflow: 'auto',
          bgcolor: 'background.paper',
          borderRadius: '8px',
          boxShadow: 24,
          padding: 4,
          border: '2px solid black',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <IconButton
          sx={{ position: 'absolute', top: 8, right: 8 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          direction={"row"}
          mt={2}
        >
        { currentTenant === "wemove" || currentTenant ==='sold'? (
          <>
            <img src={logoCube} width={30} height={30} alt="" />
            <img src={logoWeMove} width={110} height={18} alt="" />
          </>
        ) : currentTenant === "turbotenant" ? (
            <img src={TurboTenantLogo} width={150} height={44} alt="" />
        ) : currentTenant === 'movebees'? (<img src={MoveBeesLogo} width={150} height={44} alt="" /> ) 
        : currentTenant === 'summitmoving'? (<img src={SummitMovingLogo} width={150} height={44} alt="" /> ) 
        : currentTenant === 'krupp'? (<img src={KruppLogo} width={150} height={44} alt="" /> ) 
        :(<></>)
        }
        </Stack>
        <Stack p={2} spacing={2} textAlign="center">
          <Typography
            color={theme.palette.primary.main}
            fontSize={18}
            fontWeight={600}
            marginBottom={2}
          >
            YOUR IMAGES HAVE BEEN UPLOADED SUCCESSFULLY.
          </Typography>
          <Typography>{' '}</Typography>
          <Typography
            color={theme.palette.primary.main}
            fontSize={18}
            fontWeight={600}
            marginBottom={2}
          >
            PLEASE SWITCH BACK TO YOUR COMPUTER TO CONTINUE THE MOVE.
          </Typography>
          <Typography
            color={theme.palette.primary.main}
            fontSize={18}
            fontWeight={600}
          >
            THANKS
          </Typography>
          <Button
            sx={{ marginTop: 5, width: 180, alignSelf:'center' }}
            variant="contained"
            onClick={() => {
              localStorage.removeItem('jwtToken');
              localStorage.removeItem('user-object');
              localStorage.removeItem('role');
              localStorage.removeItem('sessionId');
              navigate('/login');
            }}
          >
            Exit
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default UploadCompletedModal;
