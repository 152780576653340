import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import homeBackground from "../../assets/images/sketched_home.png";
import Arrow from "../../assets/images/Arrow.png";

export const LandingPage = ({
  setIsServiceSelectionModal,
  setThisMoveRequest,
}: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <>
      <Grid
        container
        sx={{
          backgroundImage: `url(${homeBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: isMobile ? "cover" : "100% 100%",
          position: "relative",
          overflow: "hidden",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${homeBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            filter: "brightness(50%) blur(2px)", // Adjust brightness and blur for mobile
            zIndex: 0,
            opacity: isMobile ? 1 : 0, // Apply blur only on mobile
            transition: "opacity 0.3s ease-in-out",
          },
          // Revert to normal on larger screens
          "@media (min-width: 601px)": {
            "&::before": {
              opacity: 0,
            },
          },
        }}
      >
        <Grid item xs={12} md={4} p={2} marginX={"auto"}>
          <Box
            sx={{
              backdropFilter: isMobile ? "blur(1px)" : "blur(2px)",
              borderRadius: 40,
              padding: 2,
            }}
          >
            <Stack alignItems={"center"}>
              <Typography
                fontSize={40}
                mt={2}
                fontWeight={600}
                letterSpacing={1.5}
              >
                Moving{" "}
                <span style={{ fontWeight: 500, letterSpacing: 1 }}>
                  Made Easy
                </span>
              </Typography>

              <Button
                variant="outlined"
                size="large"
                sx={{
                  p: 2,
                  mt: 2,
                  width: isMobile ? "90%" : "60%",
                  border: isMobile
                    ? "1px dashed #000000"
                    : `1px dashed ${theme.palette.primary.main}`,
                  borderRadius: "50px",
                  color: isMobile ? "#000000" : theme.palette.primary.main,
                  textTransform: "none",
                  fontSize: isMobile ? 12 : 14,
                  fontWeight: isMobile ? 600 : 550,
                }}
              >
                Powered By AI Technology
              </Button>

              <Typography
                fontSize={14}
                mt={2}
                fontWeight={isMobile ? 550 : 400}
                color={isMobile ? "#FFFFFF" : "#5A5A5A"}
                textAlign={"center"}
              >
                The first humanless, DIY moving quote and booking platform in
                under 10 minutes. There’s no need for endless phone calls or
                even dealing with a salesperson.
              </Typography>

              <Box>
                <img src={Arrow} width={130} alt="" />
              </Box>

              <Button
                onClick={() => {
                  setThisMoveRequest(null);
                  setIsServiceSelectionModal(true);
                }}
                variant="contained"
                size="large"
                sx={{
                  p: 2,
                  width: isMobile ? "90%" : 250,
                  color: "#FDFCFD",
                  backgroundColor: `${theme.palette.primary.main} !important`,
                  borderRadius: "50px",
                  textTransform: "none",
                  fontSize: isMobile ? 12 : 20,
                  fontWeight: 550,
                }}
              >
                Start Your Quote
              </Button>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
