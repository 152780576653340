import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { io } from "socket.io-client";
import { jsPDF } from "jspdf";
import { findAllMoveRequestsForAdmin } from "../../redux/actions/admin";

const StyledTableCell = styled(TableCell)(({ theme }: any) => ({
  border: "1px solid rgba(224, 224, 224, 1)",
  textAlign: "center",
}));

const DisplayMoveRequests = () => {
  const thisUrl = process.env.REACT_APP_API_URL || "";
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(0);
  const [allMoveRequests, setAllMoveRequests] = useState<any>([]);
  const [hasMore, setHasMore] = useState(true);

  const numberOfHoursRequired = (move: any) => {
    let numberOfMovers = 0;
    if (move.total_cubic_feet >= 1 && move.total_cubic_feet <= 700) {
      numberOfMovers = 2;
    } else if (move.total_cubic_feet >= 701 && move.total_cubic_feet <= 1300) {
      numberOfMovers = 3;
    } else if (move.total_cubic_feet >= 1301 && move.total_cubic_feet <= 1900) {
      numberOfMovers = 4;
    } else if (move.total_cubic_feet >= 1901 && move.total_cubic_feet <= 2500) {
      numberOfMovers = 5;
    } else if (move.total_cubic_feet > 2500) {
      numberOfMovers = 6;
    }
    let totalCubicFeetOnJob = move.total_cubic_feet * 2; //load and unload
    let totalManHours = totalCubicFeetOnJob / 104;
    let approximateHours = totalManHours / numberOfMovers;
    let travelTime = 1;
    if (move.move_distance > 50) {
      travelTime = 2;
    }
    let mileageFee = 1 * move.move_distance;
    const breakTime = 0.5;
    let totalEstimatedTime = approximateHours + travelTime + breakTime;
    let costPerHour = 0;
    if (numberOfMovers === 2) {
      costPerHour = 180;
    } else if (numberOfMovers === 3) {
      costPerHour = 230;
    } else if (numberOfMovers === 4) {
      costPerHour = 280;
    } else if (numberOfMovers === 5) {
      costPerHour = 350;
    } else if (numberOfMovers === 6) {
      costPerHour = 410;
    }
    let totalMoveCost = totalEstimatedTime * costPerHour + mileageFee;
    return totalEstimatedTime;
  };
  const getAllMoveRequest = () => {
    const body = {
      offset: offset,
      limit: 5,
    };
    dispatch<any>(findAllMoveRequestsForAdmin(body))
      .then(unwrapResult)
      .then((moveRequests: any) => {
        setAllMoveRequests([...allMoveRequests, ...moveRequests]);
        setOffset(offset + moveRequests.length);
        if (moveRequests.length === 0) {
          setHasMore(false);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (allMoveRequests.length === 0) {
      getAllMoveRequest();
    }
  }, []);

  useEffect(() => {
    if (offset !== 0) {
      getAllMoveRequest();
    }
  }, [offset]);

  const formatDate = (dateString: any) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const paymentConfirmedDate = (dateString: any) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Convert UTC to EST
    const estDate = new Date(
      date.toLocaleString("en-US", { timeZone: "America/New_York" })
    );

    const estYear = estDate.getFullYear();
    const estMonth = String(estDate.getMonth() + 1).padStart(2, "0");
    const estDay = String(estDate.getDate()).padStart(2, "0");
    const estHours = String(estDate.getHours()).padStart(2, "0");
    const estMinutes = String(estDate.getMinutes()).padStart(2, "0");

    return `(EST: ${estYear}-${estMonth}-${estDay} ${estHours}:${estMinutes})`;
  };

  // const splitAddressCity = (address: string, arg: string) => {
  //   const parts = address.split(",").map((part: string) => part.trim());
  //   const zip = parts[parts.length - 1];
  //   const country = parts.length >= 2 ? parts[parts.length - 2] : undefined;
  //   const state = parts.length >= 3 ? parts[parts.length - 3] : undefined;
  //   const city = parts.length >= 4 ? parts[parts.length - 4] : undefined;

  //   if (arg === "city") {
  //     return city;
  //   } else if (arg === "state") {
  //     return state;
  //   } else if (arg === "zip") {
  //     return zip;
  //   } else if (arg === "country") {
  //     return country;
  //   }
  // };

  const checkFromAddress = (moveReq: any) => {
    if (moveReq?.home_address !== null) {
      return moveReq.home_address;
    }
    if (moveReq?.apartment !== null) {
      return moveReq.apartment?.apt_address;
    }
    if (moveReq?.storage !== null) {
      return moveReq.storage?.address;
    }
    if (moveReq?.combo_home_storage !== null) {
      return moveReq.combo_home_storage?.home_address.toString();
    }
    if (moveReq?.combo_apartment_storage !== null) {
      return moveReq.combo_apartment_storage?.apartment?.apt_address.toString();
    }
    return "---";
  };

  const [receivedMoveRequest, setReceivedMoveRequest] = useState<any>(null);

  useEffect(() => {
    const socket = io(thisUrl);

    socket.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    socket.on("connect_error", (error) => {
      console.error("WebSocket connection error:", error);
    });

    socket.on("newMoveRequest", (requestData) => {
      setReceivedMoveRequest(requestData);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const additionOfMove = async () => {
    await allMoveRequests.filter(
      (r: any) => Number(r.id) !== Number(receivedMoveRequest.id)
    );
    setAllMoveRequests([receivedMoveRequest, ...allMoveRequests]);
    // moves.push(receivedMoveRequest);
  };

  useEffect(() => {
    if (receivedMoveRequest !== null) {
      additionOfMove();
      // setAllMoveRequests((allReqs: any) => {
      //   // Check if requestData already exists in the array
      //   const filteredRequests = allReqs.filter((req: any) => req.id !== receivedMoveRequest.id);

      //   // Add the new requestData to the filtered array
      //   return [...filteredRequests, receivedMoveRequest];
      // });
    }
  }, [receivedMoveRequest]);
  const [showMoveImages, setShowMoveImages] = useState(false);
  const handleCloseModal = () => {
    setSelectedMoveRequest(null);
    setShowMoveImages(false);
  };
  const [selectedMoveRequest, setSelectedMoveRequest] = useState<any>(null);
  const handleShowMoveImages = (moveReq: any) => {
    setShowMoveImages(true);
    setSelectedMoveRequest(moveReq);
  };

  const handleDownloadCSV = () => {
    const fields = [
      "Move Order #",
      "Consumer Name",
      "Consumer Email",
      "Contract Link",
      "Consumer Contact #",
      "Cubic Feet",
      "Weight (pounds)",
      "Pickup Date",
      "Pickup Address",
      "Delivery Address",
      "Approximate Distance",
      "Total Move Price",
      "Deposit Paid",
      "Balance",
      "Confirmed At",
    ];

    const data = allMoveRequests.map((move: any, index: number) => ({
      "Move Order #": move.move_order_number,
      "Consumer Name": move.user
        ? move.user.first_name + " " + move.user.last_name
        : "-",
      "Consumer Email": move.user?.email || "-",
      "Contract Link": move.contract_link ? `${move.contract_link}` : "-",
      "Consumer Contact #": move.user?.phone_number
        ? `${move.user?.phone_number.replace(/^\+1/, "")}`
        : "",
      "Cubic Feet": move.total_cubic_feet ? Number(move.total_cubic_feet) : "-",
      "Weight (pounds)": move.total_cubic_feet
        ? Number(move.total_cubic_feet * 7)
        : "-",
      "Pickup Date": formatDate(move.pickup_date_from),
      "Pickup Address": checkFromAddress(move),
      "Delivery Address":
        move.delivery_details?.delivery_addresses[0]?.complete_address,
      "Approximate Distance": move.move_distance
        ? `${move.move_distance} miles`
        : "",
      "Total Move Price": `$ ${Math.round(move.move_payment)}`,
      "Deposit Paid": move.initial_deposit
        ? `$ ${Math.round(move.initial_deposit)}`
        : "Not Paid",
      Balance: `$ ${Number(move.move_payment) - Number(move.initial_deposit)}`,
      "Confirmed At": move.payment_response?.created_at
        ? paymentConfirmedDate(move.payment_response?.created_at)
        : "",
    }));

    const csvContent = [
      fields.join(","),
      ...data.map((row: any) =>
        fields.map((field) => `"${row[field]}"`).join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "move_requests.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const getMovers = (move: any) => {
    let numberOfMovers = 0;
    if (move.total_cubic_feet !== null) {
      if (move.total_cubic_feet >= 1 && move.total_cubic_feet <= 700) {
        numberOfMovers = 2;
      } else if (
        move.total_cubic_feet >= 701 &&
        move.total_cubic_feet <= 1300
      ) {
        numberOfMovers = 3;
      } else if (
        move.total_cubic_feet >= 1301 &&
        move.total_cubic_feet <= 1900
      ) {
        numberOfMovers = 4;
      } else if (
        move.total_cubic_feet >= 1901 &&
        move.total_cubic_feet <= 2500
      ) {
        numberOfMovers = 5;
      } else if (move.total_cubic_feet > 2500) {
        numberOfMovers = 6;
      }
    }
    if (numberOfMovers === 0) {
      return "Not calculated yet";
    }
    return numberOfMovers.toString();
  };

  const GeneratePdf = (moveRequest: any) => {
    const doc = new jsPDF();
    let yPosition = 10; // Starting position for text
    const pageHeight = doc.internal.pageSize.height; // Get the height of the page

    // Function to check and add a new page if the content exceeds the page limit
    const checkPageOverflow = (y: number) => {
      if (y >= pageHeight - 20) {
        // Add some bottom margin
        doc.addPage();
        return 10; // Reset yPosition to the top for the new page
      }
      return y;
    };
    doc.text("Inventory Summary", 10, yPosition);
    yPosition += 10;
    yPosition = checkPageOverflow(yPosition);

    const boxesRequired = moveRequest.no_of_boxes_for_boxable_items || 0;
    doc.text(`Number of Boxes Required: ${boxesRequired}`, 10, yPosition);
    yPosition += 10;
    yPosition = checkPageOverflow(yPosition);

    yPosition += 10; // Add extra space before room details
    // Loop through room details for non-bulky items
    moveRequest?.roomDetails?.forEach((room: any, roomIndex: number) => {
      const roomTitle = room.title
        ? `Items For ${room.title}:`
        : "No name given to room:";
      doc.text(roomTitle, 10, yPosition);
      yPosition += 10;
      yPosition = checkPageOverflow(yPosition);

      room.room_images?.forEach((image: any, imageIndex: number) => {
        doc.text(`Image ${imageIndex + 1}:`, 20, yPosition);
        yPosition += 10;
        yPosition = checkPageOverflow(yPosition);

        image.items?.forEach((item: any, itemIndex: number) => {
          doc.text(`${itemIndex + 1}. ${item.item_name}`, 30, yPosition);
          yPosition += 10;
          yPosition = checkPageOverflow(yPosition);
        });
      });

      yPosition += 10; // Add some space between rooms
      yPosition = checkPageOverflow(yPosition);
    });

    // Now add bulky items at the end
    let hasBulkyItems = false;
    yPosition += 20; // Add extra space before bulky items section
    doc.text("Bulky Items:", 10, yPosition);
    yPosition += 10;
    yPosition = checkPageOverflow(yPosition);

    moveRequest?.roomDetails?.forEach((room: any) => {
      room.room_images?.forEach((image: any) => {
        image.items?.forEach((item: any) => {
          if (item.is_bulky === true && item.bulky_fee !== 0) {
            hasBulkyItems = true;
            doc.text(`- ${item.item_name}`, 20, yPosition);
            doc.text(`$${item.bulky_fee.toFixed(2)}`, 180, yPosition, {
              align: "right",
            });
            yPosition += 10;
            yPosition = checkPageOverflow(yPosition);
          }
        });
      });
    });

    if (!hasBulkyItems) {
      doc.text("No bulky items", 10, yPosition);
      yPosition = checkPageOverflow(yPosition);
    }

    // Save the PDF
    doc.save("Move Inventory.pdf");
  };
  const theme = useTheme();
  useEffect(() => {
    console.log(process.env.REACT_APP_TENANT, "----llllllll");
  }, []);
  return (
    <>
      <Modal
        open={showMoveImages}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "8px",
            width: { xs: "90vw", sm: "80vw", md: "50vw", lg: "55vw" },
            boxShadow: 24,
            p: 4,
            alignItems: "center",
            justifyContent: "center",
            border: `4px solid ${theme.palette.primary.main}`,
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <Typography
            overflow={"auto"}
            sx={{ textDecoration: "underline" }}
            textAlign={"center"}
            fontStyle={"italic"}
            fontSize={18}
            fontWeight={600}
            color={theme.palette.primary.main}
          >
            INVENTORY IMAGES
          </Typography>
          {selectedMoveRequest !== null &&
            selectedMoveRequest.roomDetails?.map((room: any) => {
              return (
                <Stack key={room.id}>
                  <Typography fontWeight={600}>
                    Room Name: {room.title}
                  </Typography>
                  {room.room_images?.map((img: any, index: number) => {
                    return (
                      <Stack marginY={1} direction={"row"} key={img.id}>
                        <Typography
                          fontStyle={"italic"}
                          fontWeight={600}
                          color={theme.palette.primary.main}
                          sx={{
                            wordBreak: "break-all",
                            wordWrap: "break-word",
                          }}
                        >
                          Image {index + 1}:{" "}
                          <a
                            href={img.image_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              wordBreak: "break-all",
                              wordWrap: "break-word",
                            }}
                          >
                            {img.image_url}
                          </a>
                        </Typography>
                      </Stack>
                    );
                  })}
                </Stack>
              );
            })}
        </Box>
      </Modal>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <Button
              sx={{ backgroundColor: "#21837B" }}
              disabled={hasMore}
              variant="contained"
              onClick={handleDownloadCSV}
              endIcon={<SimCardDownloadIcon />}
            >
              {hasMore ? "Getting moves..." : "Download CSV"}
            </Button>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead sx={{ backgroundColor: theme.palette.primary.main }}>
                <TableRow>
                  <StyledTableCell>Count</StyledTableCell>
                  <StyledTableCell>MOVE ORDER #</StyledTableCell>
                  <StyledTableCell>MOVE CONTACT #</StyledTableCell>
                  <StyledTableCell>IMAGES</StyledTableCell>
                  <StyledTableCell>CONSUMER NAME</StyledTableCell>
                  <StyledTableCell>CONSUMER EMAIL</StyledTableCell>
                  {process.env.REACT_APP_TENANT === "wemove" ||
                  process.env.REACT_APP_TENANT === "turbotenant" ? (
                    <StyledTableCell>CONTRACT LINK</StyledTableCell>
                  ) : (
                    ""
                  )}
                  <StyledTableCell>NO. OF MOVERS NEEDED</StyledTableCell>
                  <StyledTableCell>NO. OF HOURS REQUIRED</StyledTableCell>
                  <StyledTableCell>INVENTORY PDF</StyledTableCell>
                  <StyledTableCell>CONSUMER CONTACT #</StyledTableCell>
                  <StyledTableCell>MOVER NAME</StyledTableCell>
                  <StyledTableCell>MOVER EMAIL</StyledTableCell>
                  <StyledTableCell>CUBIC FEET</StyledTableCell>
                  <StyledTableCell>CUBIC METERS</StyledTableCell>
                  <StyledTableCell>WEIGHT (pounds)</StyledTableCell>
                  <StyledTableCell>WEIGHT (kg's)</StyledTableCell>
                  <StyledTableCell>PICKUP DATE</StyledTableCell>
                  <StyledTableCell>PICKUP ADDRESS</StyledTableCell>
                  <StyledTableCell>DELIVERY ADDRESS</StyledTableCell>
                  <StyledTableCell>APPROXIMATE DISTANCE</StyledTableCell>
                  <StyledTableCell>DISTANCE IN KILOMETERS</StyledTableCell>
                  {/* <StyledTableCell >PACKAGING REQUIRED</StyledTableCell>
              <StyledTableCell >PACKAGING TYPE</StyledTableCell>
              <StyledTableCell >PACKAGING PRICE</StyledTableCell> */}
                  <StyledTableCell>TOTAL MOVE PRICE</StyledTableCell>
                  <StyledTableCell>DEPOSIT PAID</StyledTableCell>
                  <StyledTableCell>PACKAGING</StyledTableCell>
                  <StyledTableCell>BALANCE</StyledTableCell>
                  <StyledTableCell>CONFIRMED AT</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allMoveRequests.length !== 0 ? (
                  allMoveRequests.map((move: any, index: number) => {
                    return (
                      <TableRow
                        key={move.id}
                        // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <StyledTableCell>{index + 1}</StyledTableCell>
                        <StyledTableCell>
                          {move.move_order_number}
                        </StyledTableCell>
                        <StyledTableCell>
                          {move.move_phone_number}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Button
                            onClick={() => {
                              handleShowMoveImages(move);
                            }}
                            variant="text"
                          >
                            View Images
                          </Button>
                        </StyledTableCell>
                        <StyledTableCell>
                          {move.user
                            ? move.user?.first_name + " " + move.user?.last_name
                            : "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {move?.user?.email ? move.user?.email : "-"}
                        </StyledTableCell>
                        {process.env.REACT_APP_TENANT === "wemove" ||
                        process.env.REACT_APP_TENANT === "turbotenant" ? (
                          <StyledTableCell>
                            {move?.contract_signed === true &&
                            move.contract_link !== null ? (
                              <a
                                href={move.contract_link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Contract Link
                              </a>
                            ) : (
                              "-"
                            )}
                          </StyledTableCell>
                        ) : (
                          ""
                        )}
                        <StyledTableCell>
                          {move?.move_distance <= 100
                            ? getMovers(move).toString()
                            : "Long-Distance Move"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {move?.move_distance <= 100
                            ? Math.ceil(numberOfHoursRequired(move)).toString()
                            : "Long-Distance Move"}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Button
                            disabled={
                              move.roomDetails?.length === 0 ||
                              move.roomDetails?.room_images?.length === 0
                            }
                            onClick={() => {
                              GeneratePdf(move);
                            }}
                          >
                            DOWNLOAD INVENTORY PDF
                          </Button>
                        </StyledTableCell>
                        <StyledTableCell>
                          {move.user?.phone_number}
                        </StyledTableCell>
                        <StyledTableCell>{move.mover_name}</StyledTableCell>
                        <StyledTableCell>{move.mover_email}</StyledTableCell>
                        <StyledTableCell>
                          {move.total_cubic_feet
                            ? Number(move.total_cubic_feet)
                            : "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {move.total_cubic_feet
                            ? (
                                Number(move.total_cubic_feet) * 0.0283168
                              ).toFixed(2)
                            : "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {move.total_cubic_feet
                            ? Number(move.total_cubic_feet * 7)
                            : "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {" "}
                          {move.total_cubic_feet
                            ? (
                                Number(move.total_cubic_feet) *
                                7 *
                                0.453592
                              ).toFixed(2)
                            : "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {formatDate(move.pickup_date_from)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {checkFromAddress(move)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {" "}
                          {move.delivery_details?.delivery_addresses[0]
                            ?.complete_address ?? "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {move.move_distance
                            ? `${move.move_distance} miles`
                            : "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {move.move_distance
                            ? `${(move.move_distance * 1.60934).toFixed(2)} km`
                            : "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          $ {Math.round(move.move_payment)}
                        </StyledTableCell>
                        <StyledTableCell>
                          ${" "}
                          {move.initial_deposit
                            ? Math.round(move.initial_deposit)
                            : "Not Paid"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {move.delivery_details?.packaging?.packaging_payment
                            ? `$ ${move.delivery_details.packaging.packaging_payment}`
                            : "No"}
                        </StyledTableCell>
                        <StyledTableCell>
                          ${" "}
                          {Number(move.move_payment) -
                            Number(move.initial_deposit)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {move.payment_response?.created_at
                            ? paymentConfirmedDate(
                                move.payment_response?.created_at
                              )
                            : "-"}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <Stack alignSelf={"center"} alignItems={"center"}></Stack>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default DisplayMoveRequests;
