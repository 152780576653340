import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

const WeMoveHeader = () => {
  const currentTenant = process.env.REACT_APP_TENANT || "";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <>
      <Grid item xs={12} p={2}>
        <Typography
          fontSize={isMobile ? 18 : 22}
          fontWeight={700}
          letterSpacing={1.3}
          color={process.env.REACT_APP_TENANT==='movebees'?'#000000':'FFFFFF'}
        >
          {currentTenant === "wemove" || currentTenant === 'sold' ? (
  "WeMove.AI"
) : currentTenant === "turbotenant" ? (
  "TurboTenant AI"
) : currentTenant === "movebees" ? (
  "Movebees AI"
) : currentTenant === "krupp" ? (
  "Krupp AI"
) : currentTenant === "summitmoving" ? (
  "Summit AI"
) : (
  <></>
)}

        </Typography>
      </Grid>
    </>
  );
};

export default WeMoveHeader;
