import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TurboTenantLogo from "../../assets/logo/turbotenant_logo.png";
import MoveBeesLogo from "../../assets/logo/MoveBees-Logo-VR-Yellow.jpg";
import KruppLogo from "../../assets/logo/Krupp.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SummitMovingLogo from "../../assets/logo/SummitMoving.png";
import logoWeMove from "../../assets/logo/WeMoveNewLogo.png";
import {
  getMoveRequestById,
  updateMoveRequestById,
} from "../../redux/actions/move-request";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import InfoIcon from "@mui/icons-material/Info";
import FloorAlertModal from "../../components/modals/floorAlertModal";
import ProgressBar from "../../components/progressBar/progressBar";
import NavBar from "../../components/navbar/navBar";
import apartmentImage from "../../assets/images/new-apartment.png";
import homeOnly from "../../assets/images/Home-only.svg";
import storageOnly from "../../assets/images/Storage-only.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReactGA from "react-ga4";
import ContactSupport from "../../components/contactSupport";
import { NeedHelp } from "../../components/needHelp";
import { CustomCircularProgress } from "../../components/circularProgress";
import LogoHeader from "../../components/logo-header/logoHeader";

const DeliveryLocationInfo = () => {
  const theme = useTheme();
  const notSeelectedButtonStyle = {
    width: 150,
    height: 41,
    color: "#002366",
    fontSize: 10,
    fontWeight: 550,
    border: `1px solid #002366`,
    borderRadius: 4,
  };

  const selectedButtonStyle = {
    width: 150,
    height: 41,
    backgroundColor: `#002366 !important`,
    color: "#FDFCFD",
    fontSize: 10,
    fontWeight: 550,
    borderRadius: 4,
  };

  const typeSelected = {
    width: 170,
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "#FDFCFD",
  };

  const typeNotSelected = {
    width: 170,
    color: "#5859DF",
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { moveRequestId } = useParams();
  const [thisMoveRequestId, setThisMoveRequestId] = useState(null);

  const [locationType, setLocationType] = useState("");
  const [isStairs, setIsStairs] = useState<any>(null);
  const [isElevator, setIsElevator] = useState<any>(null);
  const [isFloorAlertModal, setIsFloorAlertModal] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [destinationFloor, setDestinationFloor] = useState<any>(null);

  const [formError, setFormError] = useState("");

  const fetchMoveRequestById = (moveRequestId: any) => {
    dispatch<any>(getMoveRequestById(moveRequestId))
      .then(unwrapResult)
      .then((thisMoveRequest: any) => {
        setThisMoveRequestId(thisMoveRequest.id);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const updateThisMoveRequest = (body: any) => {
    setIsPageLoading(true);
    const params = {
      id: thisMoveRequestId,
      ...body,
    };
    dispatch<any>(updateMoveRequestById(params))
      .then(unwrapResult)
      .then((thisMoveRequest: any) => {
        setTimeout(() => {
          setIsPageLoading(false);
          navigate(`/PickupDate/${thisMoveRequest.canonical_id}`);
          // navigateToLastLeft(thisMoveRequest);
        }, 1300);
      })
      .catch((err: any) => {
        console.log(err);
        setTimeout(() => {
          setIsPageLoading(false);
        }, 1300);
      });
  };

  const navigateToLastLeft = (moveRequest: any) => {
    if (!moveRequest.delivery_details) {
      navigate(`/AddressInfo/${moveRequest.canonical_id}`);
    } else {
      if (moveRequest.delivery_details?.delivery_addresses?.length === 0) {
        navigate(`/AddressInfo/${moveRequest.canonical_id}`);
      } else {
        if (
          moveRequest.delivery_details?.delivery_addresses[0]
            .delivery_location_type === null
        ) {
          navigate(`/DeliveryLocationInfo/${moveRequest.canonical_id}`);
        } else {
          if (
            moveRequest.pickup_date_from === null &&
            moveRequest.pickup_date_to === null
          ) {
            navigate(`/PickupDate/${moveRequest.canonical_id}`);
          } else {
            if (moveRequest.delivery_details.open_location === null) {
              navigate(`/TruckInfo/${moveRequest.canonical_id}`);
            } else {
              if (moveRequest.items?.length === 0) {
                navigate(`/itemsInfo/${moveRequest.canonical_id}`);
              } else {
                if (
                  moveRequest.delivery_details?.packagaing_required === null
                ) {
                  navigate(`/packages/${moveRequest.canonical_id}`);
                } else {
                  navigate(`/move-summary/${moveRequest.canonical_id}`);
                }
              }
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    fetchMoveRequestById(moveRequestId);
    setIsFloorAlertModal(true);
  }, []);

  const [url, setUrl] = useState(process.env.REACT_APP_URL || "");

  if (url === "https://stagingapi.wemoveai.com/") {
    ReactGA.send({
      hitType: "pageview",
      page: "/DeliveryLocationInfo/:moveRequestId",
      title: "DeliveryLocationInfo Staging",
    });
  }

  if (url === "https://productionapi.wemoveai.com/") {
    ReactGA.send({
      hitType: "pageview",
      page: "/DeliveryLocationInfo/:moveRequestId",
      title: "DeliveryLocationInfo Production",
    });
  }

  if (url === "https://turbotenantapi.wemoveai.com/") {
    ReactGA.send({
      hitType: "pageview",
      page: "/DeliveryLocationInfo/:moveRequestId",
      title: "DeliveryLocationInfo Turbotenant",
    });
  }

  if (url === "https://soldapi.wemoveai.com/") {
    ReactGA.send({
      hitType: "pageview",
      page: "/DeliveryLocationInfo/:moveRequestId",
      title: "DeliveryLocationInfo Sold",
    });
  }
  const currentTenant = process.env.REACT_APP_TENANT;
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <>
      {/* <NavBar moveRequestId={moveRequestId} /> */}
      {/* <Stack
        direction="row"
        alignItems="center"
        justifyContent="center" // Centers the logo and text
        width="100vw" // Full width of the viewport
        sx={{ mt: 2 }} // Adds some margin to give spacing from the top
      >
        {currentTenant === "wemove" || currentTenant === "sold" ? (
          <img
            src={logoWeMove}
            alt=""
            style={{ width: "30px", height: "30px" }}
          />
        ) : currentTenant === "turbotenant" ? (
          <img
            src={TurboTenantLogo}
            alt=""
            style={{ width: "150px", height: "44px" }}
          />
        ) : currentTenant === "movebees" ? (
          <img
            src={MoveBeesLogo}
            alt=""
            style={{ width: "240px", height: "66px" }}
          />
        ) : currentTenant === "summitmoving" ? (
          <img
            src={SummitMovingLogo}
            alt=""
            style={{ width: "150px", height: "60px" }}
          />
        ) : currentTenant === "krupp" ? (
          <img
            src={KruppLogo}
            alt=""
            style={{ width: "150px", height: "44px" }}
          />
        ) : (
          <></>
        )}

        <Typography
          color={theme.palette.primary.main}
          variant="h6"
          style={{ marginLeft: "10px" }}
        >
          {currentTenant === "wemove" || currentTenant === "sold"
            ? "WeMove.ai"
            : currentTenant === "turbotenant"
              ? "TurboTenant"
              : currentTenant === "movebees"
                ? "Movebees"
                : currentTenant === "krupp"
                  ? "Krupp Moving"
                  : currentTenant === "summitmoving"
                    ? "Summit Moving"
                    : ""}
        </Typography>
      </Stack> */}
      <LogoHeader />
      <Grid container>
        <Grid item xs={12} md={8} p={2} marginX={"auto"}>
          <Box
            sx={{
              width: { xs: "90vw", sm: "80vw", md: "70vw", lg: "60vw" },
              height: isMobile ? "90vh" : "auto",
              p: { xs: 2, sm: 4 }, // Responsive padding for small screens
              mt: { xs: 3, sm: 5 },
              border: "1px solid #0000001A",
              borderRadius: "8px",
              overflow: "auto",
              backgroundColor: "#FBFBFB",
            }}
          >
            <NeedHelp />
            <Stack
              sx={{
                alignItems: isMobile ? "center" : "flex-start",
                overflow: "auto",
                mb: 3, // Margin bottom for spacing
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{ mb: 2 }}
              >
                <Stack>
                  <Typography fontSize={isMobile ? 18 : 24} fontWeight={500}>
                    Delivery Location Type
                  </Typography>
                  <Typography fontSize={isMobile ? 10 : 16} color={"#646464"}>
                    Fill in all info correctly
                  </Typography>
                </Stack>
                <CustomCircularProgress value={6} total={8} />
              </Stack>
            </Stack>
            <Typography
              fontSize="16px"
              fontWeight={700}
              color={"#262626"}
              mt={3}
            >
              Please select your delivery location type!
            </Typography>
            <Stack
              padding={1}
              gap={1}
              justifyContent={"center"}
              display={"flex"}
              flexWrap={"wrap"}
            >
              <Button
                onClick={() => setLocationType("Home")}
                // sx={locationType === "Home" ? typeSelected : typeNotSelected}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor:
                    locationType === "Home" ? "#002366" : "#f0f0f0",
                  color: locationType === "Home" ? "#fff" : "#4f4f4f",
                  fontWeight: "bold",
                  padding: "12px 16px",
                  borderRadius: 2,
                  textTransform: "none",
                  border:
                    locationType === "Home"
                      ? "1px solid #002366"
                      : "1px solid #f0f0f0",
                  "&:hover": {
                    backgroundColor:
                      locationType === "Home" ? "#002366" : "#e0e0e0",
                  },
                }}
              >
                <Typography
                  fontWeight={500}
                  color={locationType === "Home" ? "#FDFCFD" : "#4A5E6D"}
                  fontSize={12}
                >
                  Home
                </Typography>
                {locationType === "Home" && (
                  <CheckCircleIcon sx={{ color: "#fff" }} />
                )}
              </Button>

              <Button
                onClick={() => setLocationType("Apartment")}
                // sx={
                //   locationType === "Apartment" ? typeSelected : typeNotSelected
                // }
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor:
                    locationType === "Apartment" ? "#002366" : "#f0f0f0",
                  color: locationType === "Apartment" ? "#fff" : "#4f4f4f",
                  fontWeight: "bold",
                  padding: "12px 16px",
                  borderRadius: 2,
                  textTransform: "none",
                  border:
                    locationType === "Apartment"
                      ? "1px solid #002366"
                      : "1px solid #f0f0f0",
                  "&:hover": {
                    backgroundColor:
                      locationType === "Apartment" ? "#002366" : "#e0e0e0",
                  },
                }}
              >
                <Typography
                  fontWeight={500}
                  color={locationType === "Apartment" ? "#FDFCFD" : "#4A5E6D"}
                  fontSize={12}
                >
                  Apartment
                </Typography>
                {locationType === "Apartment" && (
                  <CheckCircleIcon sx={{ color: "#fff" }} />
                )}
              </Button>

              <Button
                onClick={() => setLocationType("Storage")}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor:
                    locationType === "Storage" ? "#002366" : "#f0f0f0",
                  color: locationType === "Storage" ? "#fff" : "#4f4f4f",
                  fontWeight: "bold",
                  padding: "12px 16px",
                  borderRadius: 2,
                  textTransform: "none",
                  border:
                    locationType === "Storage"
                      ? "1px solid #002366"
                      : "1px solid #f0f0f0",
                  "&:hover": {
                    backgroundColor:
                      locationType === "Storage" ? "#002366" : "#e0e0e0",
                  },
                }}
              >
                <Typography
                  fontWeight={500}
                  color={locationType === "Storage" ? "#FDFCFD" : "#4A5E6D"}
                  fontSize={12}
                >
                  Storage
                </Typography>
                {locationType === "Storage" && (
                  <CheckCircleIcon sx={{ color: "#fff" }} />
                )}
              </Button>
            </Stack>

            {locationType !== "Home" && locationType !== "" ? (
              <>
                <Stack mt={2} alignItems={"center"} direction={"row"}>
                  <Typography
                    alignItems={"center"}
                    mt={2}
                    fontSize="14px"
                    fontWeight={500}
                    color={"#797979"}
                  >
                    Are Stairs or an Elevator needed?
                  </Typography>
                </Stack>
                <Typography
                  fontWeight={400}
                  fontSize={14}
                  sx={{ fontStyle: "italic" }}
                  color={"#797979"}
                >
                  choose one
                </Typography>
                <Stack
                  direction="row"
                  mt={2}
                  spacing={2}
                  justifyContent={"center"}
                >
                  <Button
                    size="large"
                    sx={
                      isElevator === "stairs"
                        ? selectedButtonStyle
                        : notSeelectedButtonStyle
                    }
                    onClick={() => {
                      setIsElevator("stairs");
                      setIsStairs(true);
                      setDestinationFloor(null);
                      setFormError("");
                    }}
                  >
                    Stairs
                  </Button>
                  <Button
                    size="large"
                    sx={
                      isElevator === "elevator"
                        ? selectedButtonStyle
                        : notSeelectedButtonStyle
                    }
                    onClick={() => {
                      setIsElevator("elevator");
                      setIsStairs(false);
                      setDestinationFloor(null);
                      setFormError("");
                    }}
                  >
                    Elevator
                  </Button>

                  <Button
                    sx={
                      isElevator === "not-applicable"
                        ? selectedButtonStyle
                        : notSeelectedButtonStyle
                    }
                    onClick={() => {
                      setIsElevator("not-applicable");
                      setIsStairs(false);
                      setDestinationFloor(0);
                      setFormError("");
                    }}
                  >
                    Not Applicable
                  </Button>
                </Stack>

                {isElevator !== "not-applicable" && (
                  <>
                    <Typography
                      mt={2}
                      fontSize="14px"
                      fontWeight={500}
                      color={"#797979"}
                    >
                      What floor are you on?
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      fullWidth
                      placeholder={""}
                      value={destinationFloor || ""}
                      size="small"
                      onChange={(event) =>
                        setDestinationFloor(parseInt(event.target.value) || 0)
                      }
                      inputProps={{
                        maxLength: 2,
                        style: {
                          background: "#5A7BFC14",
                          borderRadius: "8px",
                        },
                      }}
                      sx={{
                        backgroundColor: "#FFFFFF",
                        marginTop: 1,
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#5A7BFC59",
                            borderWidth: "1.43",
                            borderRadius: "8px",
                          },
                        },
                      }}
                    />
                  </>
                )}
              </>
            ) : (
              <></>
            )}

            <Box p={3}>
              {formError && <Alert severity="error">{formError}</Alert>}
            </Box>
            <Box
              sx={{
                backgroundColor: "#fefae8", // Light yellow background color
                borderRadius: 2,
                padding: 2,
                marginY: 2,
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#a78b49", // Darker shade for the label background
                  borderRadius: 1,
                  paddingX: 1.5,
                  paddingY: 0.5,
                  display: "inline-block",
                  marginBottom: 1,
                }}
              >
                <Typography variant="body2" fontWeight="bold" color="white">
                  Important Note
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                If your move involves an elevator, no worries! Reserving the
                elevator with your building helps keep everything on schedule
                and stress-free. This way, there are no surprises, and your move
                stays hassle-free! Speak to your building manager about elevator
                use.
              </Typography>
            </Box>
            <Stack direction="row" mt={3} spacing={2} justifyContent={"center"}>
              <Button
                onClick={() => {
                  navigate(-1);
                }}
                sx={{
                  width: 120,
                  height: 45,
                  backgroundColor: "#E9E9EA",
                  color: "#808080",
                  fontSize: 12,
                  fontWeight: 550,
                  borderRadius: 4,
                }}
              >
                Back
              </Button>

              <Button
                disabled={locationType === ""}
                onClick={() => {
                  if (locationType !== "") {
                    if (locationType === "Home") {
                      setFormError("");
                      updateThisMoveRequest({
                        delivery_details: {
                          delivery_addresses: [
                            {
                              delivery_location_type: "Home",
                              stiars_present: null,
                              is_elevator_accessible: null,
                              no_of_flights: 0,
                              floor_no: 0,
                            },
                          ],
                        },
                      });
                    } else if (
                      locationType === "Apartment" ||
                      locationType === "Storage"
                    ) {
                      if (isElevator !== null && isStairs !== null) {
                        if (destinationFloor !== null) {
                          setFormError("");
                          updateThisMoveRequest({
                            delivery_details: {
                              delivery_addresses: [
                                {
                                  delivery_location_type: locationType,
                                  stiars_present: isStairs,
                                  is_elevator_accessible:
                                    isElevator === "elevator" ? true : false,
                                  no_of_flights: 0,
                                  floor_no: destinationFloor,
                                },
                              ],
                            },
                          });
                        } else {
                          setFormError("Please enter floor number.");
                        }
                      } else {
                        setFormError(
                          "Please select if Elevator or Stairs would be needed."
                        );
                      }
                    }
                  } else {
                    setFormError("Please select a location type.");
                  }
                }}
                size="medium"
                sx={{
                  width: 180,
                  height: 45,
                  backgroundColor: `${theme.palette.primary.main} !important`,
                  color: "#FFFFFF",
                  fontSize: 12,
                  fontWeight: 550,
                  borderRadius: 4,
                }}
              >
                Continue
              </Button>
            </Stack>
          </Box>
        </Grid>
      </Grid>

      {isPageLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isPageLoading}
          onClick={() => {
            setIsPageLoading(true);
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
};

export default DeliveryLocationInfo;
