import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import TurboTenantLogo from "../../assets/logo/turbotenant_logo.png";
import MoveBeesLogo from "../../assets/logo/MoveBees-Logo-VR-Yellow.jpg";
import KruppLogo from "../../assets/logo/Krupp.png";
import logoWeMove from "../../assets/logo/WeMoveNewLogo.png";
import SummitMovingLogo from "../../assets/logo/SummitMoving.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  getMoveRequestById,
  updateMoveRequestById,
} from "../../redux/actions/move-request";
import { unwrapResult } from "@reduxjs/toolkit";
import FrontDoorAlertModal from "../../components/modals/frontDoorAlertModal";
import ProgressBar from "../../components/progressBar/progressBar";
import NavBar from "../../components/navbar/navBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReactGA from "react-ga4";
import ContactSupport from "../../components/contactSupport";
import { CustomCircularProgress } from "../../components/circularProgress";
import { NeedHelp } from "../../components/needHelp";
import LogoHeader from "../../components/logo-header/logoHeader";

const colorBadge = {
  marginTop: 2,
  fontSize: 10,
  fontWeight: 800,
  backgroundColor: "#08123B",
  color: "#FFFFFF",
  width: "50px",
  height: "26px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "2px",
};

const BUTTON_TITLES = [
  "I don't know exact",
  "Less than 75 Feet",
  "More than 75 Feet",
];

const TruckInfo = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const baseButtonStyle = {
    width: 130,
    height: 40,
    fontSize: 12,
    fontWeight: 550,
    border: `1px solid ${theme.palette.primary.main}`,
  };
  const selectedButtonStyle = {
    backgroundColor: "#002E8A",
    color: "#fff",
    justifyContent: "space-between",
    borderRadius: 2,
    padding: 1.5,
    width: "100%",
    "&:hover": {
      backgroundColor: "#002E8A",
    },
  };

  const notSelectedButtonStyle = {
    backgroundColor: "#f2f2f2",
    color: "text.primary",
    justifyContent: "flex-start",
    borderRadius: 2,
    padding: 1.5,
    width: "100%",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { moveRequestId } = useParams();
  const [thisMoveRequestId, setThisMoveRequestId] = useState(null);

  const [isFrontDoorAlertModalVisible, setIsFrontDoorAlertModalVisible] =
    useState(false);
  const [shuttleButton, setShuttleButton] = useState("I don't know exact");
  const [selectedButton, setSelectedButton] = useState("I don't know exact");
  const [isPageLoading, setIsPageLoading] = useState(false);

  const handleButtonClick = (value: any) => {
    setSelectedButton(value);
  };

  const handleShuttleButtonClick = (value: any) => {
    setShuttleButton(value);
  };

  const navigateToLastLeft = (moveRequest: any) => {
    if (moveRequest.location_type === "storage-only") {
      navigate(`/itemsInfo/${moveRequest.canonical_id}`);
    } else {
      navigate(`/boxing-info/${moveRequest.canonical_id}`);
    }
  };

  const fetchMoveRequestById = (moveRequestId: any) => {
    dispatch<any>(getMoveRequestById(moveRequestId))
      .then(unwrapResult)
      .then((thisMoveRequest: any) => {
        setThisMoveRequestId(thisMoveRequest.id);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const updateThisMoveRequest = (body: any) => {
    setIsPageLoading(true);
    const params = {
      id: thisMoveRequestId,
      ...body,
    };
    dispatch<any>(updateMoveRequestById(params))
      .then(unwrapResult)
      .then((thisMoveRequest: any) => {
        setTimeout(() => {
          setIsPageLoading(false);
          navigateToLastLeft(thisMoveRequest);
        }, 1300);
      })
      .catch((err: any) => {
        console.log(err);
        setTimeout(() => {
          setIsPageLoading(false);
        }, 1300);
      });
  };

  useEffect(() => {
    fetchMoveRequestById(moveRequestId);
  }, []);

  useEffect(() => {
    if (
      selectedButton === "More than 75 Feet" ||
      shuttleButton === "More than 75 Feet"
    ) {
      setIsFrontDoorAlertModalVisible(true);
    }
  }, [selectedButton, shuttleButton]);

  const [url, setUrl] = useState(process.env.REACT_APP_URL || "");

  if (url === "https://stagingapi.wemoveai.com/") {
    ReactGA.send({
      hitType: "pageview",
      page: "/TruckInfo/:moveRequestId",
      title: "TruckInfo Staging",
    });
  }

  if (url === "https://productionapi.wemoveai.com/") {
    ReactGA.send({
      hitType: "pageview",
      page: "/TruckInfo/:moveRequestId",
      title: "TruckInfo Production",
    });
  }

  if (url === "https://turbotenantapi.wemoveai.com/") {
    ReactGA.send({
      hitType: "pageview",
      page: "/TruckInfo/:moveRequestId",
      title: "TruckInfo Turbotenant",
    });
  }

  if (url === "https://soldapi.wemoveai.com/") {
    ReactGA.send({
      hitType: "pageview",
      page: "/TruckInfo/:moveRequestId",
      title: "TruckInfo Sold",
    });
  }
  const currentTenant = process.env.REACT_APP_TENANT;

  return (
    <>
      {/* <Stack
        direction="row"
        alignItems="center"
        justifyContent="center" // Centers the logo and text
        width="100vw" // Full width of the viewport
        sx={{ mt: 2 }} // Adds some margin to give spacing from the top
      >
        {currentTenant === "wemove" || currentTenant === "sold" ? (
          <img
            src={logoWeMove}
            alt=""
            style={{ width: "30px", height: "30px" }}
          />
        ) : currentTenant === "turbotenant" ? (
          <img
            src={TurboTenantLogo}
            alt=""
            style={{ width: "150px", height: "44px" }}
          />
        ) : currentTenant === "movebees" ? (
          <img
            src={MoveBeesLogo}
            alt=""
            style={{ width: "240px", height: "66px" }}
          />
        ) : currentTenant === "summitmoving" ? (
          <img
            src={SummitMovingLogo}
            alt=""
            style={{ width: "150px", height: "60px" }}
          />
        ) : currentTenant === "krupp" ? (
          <img
            src={KruppLogo}
            alt=""
            style={{ width: "150px", height: "44px" }}
          />
        ) : (
          <></>
        )}

        <Typography
          color={theme.palette.primary.main}
          variant="h6"
          style={{ marginLeft: "10px" }}
        >
          {currentTenant === "wemove" || currentTenant === "sold"
            ? "WeMove.ai"
            : currentTenant === "turbotenant"
              ? "TurboTenant"
              : currentTenant === "movebees"
                ? "Movebees"
                : currentTenant === "krupp"
                  ? "Krupp Moving"
                  : currentTenant === "summitmoving"
                    ? "Summit Moving"
                    : ""}
        </Typography>
      </Stack> */}
      <LogoHeader />
      {/* <NavBar moveRequestId={moveRequestId} /> */}
      <Grid container>
        <Grid item xs={12} md={8} p={2} marginX={"auto"}>
          <Box
            sx={{
              width: { xs: "90vw", sm: "80vw", md: "70vw", lg: "60vw" },
              height: isMobile ? "90vh" : "auto",
              p: { xs: 2, sm: 4 }, // Responsive padding for small screens
              mt: { xs: 3, sm: 5 },
              border: "1px solid #0000001A",
              borderRadius: "8px",
              overflow: "auto",
              backgroundColor: "#FBFBFB",
            }}
          >
            <NeedHelp />
            <Stack
              sx={{
                alignItems: isMobile ? "center" : "flex-start",
                overflow: "auto",
                mb: 3, // Margin bottom for spacing
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
                sx={{ mb: 2 }}
              >
                <Stack>
                  <Typography fontSize={isMobile ? 18 : 24} fontWeight={500}>
                    Delivery Location Type
                  </Typography>
                  <Typography fontSize={isMobile ? 10 : 16} color={"#646464"}>
                    Fill in all info correctly
                  </Typography>
                </Stack>
                <CustomCircularProgress value={8} total={10} />
              </Stack>
            </Stack>
            <Stack mt={2} alignItems={"center"} direction={"row"}>
              <Typography
                mt={2}
                fontSize={14}
                fontWeight={500}
                color={"#262626"}
              >
                Location Information
              </Typography>
            </Stack>
            <Box
              sx={{
                backgroundColor: "#f7f7f7",
                borderRadius: 2,
                padding: 3,
                width: "90%",
                marginX: "auto",
                marginY: 2,
              }}
            >
              <Typography fontSize={14} fontWeight={500}>
                Distance from Truck Parking to Entrance?
              </Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Select Any
              </Typography>
              <Stack mt={2} spacing={2} justifyContent={"center"}>
                {BUTTON_TITLES.map((value) => (
                  <Button
                    key={value}
                    size="large"
                    variant="contained"
                    sx={
                      selectedButton === value
                        ? selectedButtonStyle
                        : notSelectedButtonStyle
                    }
                    onClick={() => handleButtonClick(value)}
                    endIcon={
                      selectedButton === value ? (
                        <Box
                          component="span"
                          sx={{
                            width: 20,
                            height: 20,
                            borderRadius: "50%",
                            backgroundColor: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="caption"
                            sx={{ color: "#002E8A" }}
                          >
                            ✓
                          </Typography>
                        </Box>
                      ) : null
                    }
                  >
                    {value}
                  </Button>
                ))}
              </Stack>
            </Box>

            {/* {selectedButton === "I don't know exact" && (
              <>
                <Typography mt={2} fontSize={16} color={"#667085"}>
                  Additional fee may be applied
                  <br /> based on the following
                </Typography>

                <Typography sx={colorBadge}>01</Typography>
                <Typography mt={2} fontSize={16} color={"#667085"}>
                  Can we get a large truck in front of your location?
                </Typography>
              </>
            )} */}

            {/* {selectedButton === "Less than 75 Feet" && (
              <>
                <Typography mt={2} fontSize={16} color={"#667085"}>
                  Additional fee may be applied
                  <br /> based on the following
                </Typography>

                <Typography sx={colorBadge}>01</Typography>
                <Typography mt={2} fontSize={16} color={"#667085"}>
                  Can we get a large truck in front of your location?
                </Typography>
              </>
            )} */}

            {/* {selectedButton === "NO" && (
              <>
                <Typography sx={colorBadge}>01</Typography>
                <Typography mt={2} fontSize={16} color={"#667085"}>
                  Since you clicked no
                </Typography>
                <Typography mt={2} fontSize={16} color={"#667085"}>
                  If the delivery driver is unable to get in front of your
                  delivery destination, there may be an additional charge for a
                  long carry (75 feet or more away)
                </Typography>
              </>
            )} */}

            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              spacing={2}
            >
              <Button
                onClick={() => {
                  navigate(-1);
                }}
                sx={{
                  width: 120,
                  height: 45,
                  backgroundColor: "#E9E9EA",
                  color: "#808080",
                  fontSize: 12,
                  fontWeight: 550,
                  borderRadius: 4,
                }}
              >
                Back
              </Button>
              <Button
                onClick={() => {
                  updateThisMoveRequest({
                    delivery_details: {
                      open_location:
                        selectedButton === "Less than 75 Feet"
                          ? "YES"
                          : selectedButton === "More than 75 Feet"
                            ? "NO"
                            : "NULL",
                      shuttle_required:
                        selectedButton === "Less than 75 Feet"
                          ? "YES"
                          : selectedButton === "More than 75 Feet"
                            ? "NO"
                            : "NULL",
                    },
                  });
                }}
                variant="contained"
                size="medium"
                sx={{
                  width: 180,
                  height: 45,
                  backgroundColor: `${theme.palette.primary.main} !important`,
                  color: "#FFFFFF",
                  fontSize: 12,
                  fontWeight: 550,
                  borderRadius: 4,
                }}
              >
                Continue
              </Button>
            </Stack>
          </Box>

          {isPageLoading && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isPageLoading}
              onClick={() => {
                setIsPageLoading(true);
              }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default TruckInfo;
