import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    tableCellClasses,
    useTheme,
  } from "@mui/material";
  import AddItemModal from "../../components/modals/addItemModal";
  import { useEffect, useState } from "react";
  import Subtract from "@mui/icons-material/Remove";
  import EditIcon from "@mui/icons-material/Create";
  import InfoIcon from "@mui/icons-material/Info";
  import { useNavigate, useParams } from "react-router-dom";
  import { useDispatch } from "react-redux";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
  import { getMoveRequestById, updateMoveRequestById } from "../../redux/actions/move-request";
  import { unwrapResult } from "@reduxjs/toolkit";
  import InputAlertModal from "../../components/modals/inputAlertModal";
  import ProgressBar from "../../components/progressBar/progressBar";
  import NavBar from "../../components/navbar/navBar";
  import { getInventoryVolume, updateRoomDetails } from "../../redux/actions/room-details";
  import PackagingServiceModal from "../../components/modals/packagingServiceModal";
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";
  import AddCircleIcon from '@mui/icons-material/AddCircle';
  import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
  import { io } from "socket.io-client";
  import UploadMoreImagesModal from "../../components/modals/uploadMoreImagesModal";  

  const iconButtonStyle = {
    width: "15px",
    height: "15px",
    color: "white",
    backgroundColor: "#5858E0",
    "&:hover": {
      backgroundColor: "#5858E0",
      opacity: "0.9",
    },
  };
  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#08123B",
      color: "#FFFFFF",
      borderLeft: "1px solid rgba(224, 224, 224, 1)",
      width: "fit-content",
      fontSize: 15,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      fontWeight: "500 !important",
      backgroundColor: "#FDFBFB",
      border: "1px solid #FFFFFF !important",
    },
  }));
  
  const ModifyItems = () => {
    const theme = useTheme();
    const addButton = {
      width: 80,
      height: 30,
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: "#FFFFFF",
      fontSize: 10,
      fontWeight: 600,
    };
    const blueButton = {
      width: 150,
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: "#FFFFFF",
      fontSize: 12,
      fontWeight: 550,
    };
    const dispatch = useDispatch();
    const { moveRequestId } = useParams();
    const thisUrl = process.env.REACT_APP_API_URL || "";
  
    const [canonicalId, setCanonicalId] = useState("");
    const [moveRequest, setMoveRequest] = useState<any>(null);
    const [thisMoveRequestId, setThisMoveRequestId] = useState(null);
    const [actionType, setActionType] = useState("");
  
    const [isAddItemModal, setIsAddItemModal] = useState(false);
    const [isInputAlertModal, setIsInputAlertModal] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isPackaginServiceModal, setIsPackaginServiceModal] = useState(false);
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const [uploadMoreImagesModalVisile, setUploadMoreImagesModalVisile] = useState(false);
  
    const [editItemInfo, setEditItemInfo] = useState(null);
    const [itemIndex, setItemIndex] = useState(null);
    const [roomId, setRoomId] = useState(null);
    const [roomDetailsArray, setRoomDetailsArray] = useState<any>([]);
    const [itemsCount, setItemsCount] = useState(null);
    const [imageId, setImageId] = useState<any>(null)
    // const handleAddItemModal = (roomId: any, item?: any, index?: any) => {
    //   if (item) {
    //     setEditItemInfo(item);
    //     setItemIndex(index);
    //   } else {
    //     setEditItemInfo(null);
    //     setItemIndex(null);
    //   }
    //   setRoomId(roomId);
    //   setIsAddItemModal(true);
    // };
    const handleAddItemModal = (roomId: any, imageId: any, item?: any, index?: any) => {
      if (item) {
        setEditItemInfo(item);
        setItemIndex(index);
      } else {
        setEditItemInfo(null);
        setItemIndex(null);
      }
      setRoomId(roomId);
      setImageId(imageId);
      setIsAddItemModal(true);
    };
    
  
    // const handleSubtract = (roomid: any, itemIndex: number) => {
    //   if (
    //     editItemInfo &&
    //     editItemInfo === roomDetailsArray.items.item[itemIndex]
    //   ) {
    //     setEditItemInfo(null);
    //   }
    //   setRoomDetailsArray((prevRoomDetailsArray: any) => {
    //     return prevRoomDetailsArray.map((room: any) => {
    //       if (room.id === roomid) {
    //         const updatedItems = room.items.filter(
    //           (_: any, index: any) => index !== itemIndex
    //         );
    //         return { ...room, items: updatedItems };
    //       }
    //       return room;
    //     });
    //   });
    // };
    const handleSubtract = (roomId: any, imageId: any, itemIndex: number) => {
      if (
        editItemInfo &&
        editItemInfo === roomDetailsArray.find(
          (room: any) => room.id === roomId
        ).room_images.find((image: any) => image.id === imageId).items[itemIndex]
      ) {
        setEditItemInfo(null);
      }
    
      setRoomDetailsArray((prevRoomDetailsArray: any) => {
        return prevRoomDetailsArray.map((room: any) => {
          if (room.id === roomId) {
            const updatedRoomImages = room.room_images.map((image: any) => {
              if (image.id === imageId) {
                const updatedItems = image.items.filter(
                  (_: any, index: any) => index !== itemIndex
                );
                return { ...image, items: updatedItems };
              }
              return image;
            });
            return { ...room, room_images: updatedRoomImages };
          }
          return room;
        });
      });
    };
    
    const [numberOfBoxexRequired, setNumberOfBoxexRequired] = useState<any>(null)
    const fetchMoveRequestById = (moveRequestId: any) => {
      dispatch<any>(getMoveRequestById(moveRequestId))
        .then(unwrapResult)
        .then((thisMoveRequest: any) => {
          console.log(thisMoveRequest,'->>>>>>>>>>>>>')
          setNumberOfBoxexRequired(Number(thisMoveRequest.no_of_boxes_for_boxable_items))
          const filteredRoomDetails = thisMoveRequest.roomDetails
    .filter((room: any) => room.room_images && room.room_images.length > 0)
    .map((room: any) => ({
      ...room,
      room_images: room.room_images.map((image: any) => ({
        ...image,
        items: image.items.map((item: any) => ({
          item_name: item.item_name || "",
          item_width: item.item_width || "",
          item_height: item.item_height || "",
          item_length: item.item_length || "",
          is_bulky: item.is_bulky || false
        })),
      })),
    }));
  
          // const filteredRoomDetails = thisMoveRequest.roomDetails
          //   .filter((room: any) => room.items && room.items.length > 0)
          //   .map((room: any) => ({
          //     ...room,
          //     items: room.items.map((item: any) => ({
          //       item_name: item.item_name || "",
          //       item_width: item.item_width || "",
          //       item_height: item.item_height || "",
          //       item_length: item.item_length || "",
          //     })),
          //   }));
  
          setMoveRequest(thisMoveRequest);
          setRoomDetailsArray(filteredRoomDetails);
          setThisMoveRequestId(thisMoveRequest.id);
        })
        .catch((err: any) => {
          console.log(err);
        });
    };

    const calculateBulkyFee = async (move:any) => {
      let total_bulky_fee = 0;
      move?.roomDetails.forEach((room: any) => {
        room.room_images?.forEach((image: any) => {
          image.items?.forEach((item: any) => {
            if (item.is_bulky && item.bulky_fee !== 0) {
              total_bulky_fee += item.bulky_fee;
            }
          });
        });
      });
      return total_bulky_fee;
    }
  
    const updateThisMoveRequest = async () => {
      const previous_bulky_fee = await calculateBulkyFee(moveRequest);
      console.log('previous bulky fee --->>',previous_bulky_fee)
      setIsPageLoading(true);
      localStorage.removeItem('action-type')
      await Promise.all(
        roomDetailsArray.map(async (room: any) => {
          room.room_images.map(async(img:any)=>{
            console.log(img.items,'->>>THE ITEMS>>>')
            const body = {
              roomDetailId: room.id,
              roomImageId: img.id,
              items: img.items,
            };
            await dispatch<any>(updateRoomDetails(body))
              .then(unwrapResult)
              .then((res: any) => {
                console.log("Room updated", res);
              })
              .catch((err: any) => {
                console.log(err);
              });
            
          })
        })
      );
      const params = {
        id: thisMoveRequestId,
        no_of_boxes_for_boxable_items: numberOfBoxexRequired,
        boxable_items_volume: Number(numberOfBoxexRequired)*3,
        contract_signed: false
      }
      dispatch<any>(updateMoveRequestById(params))
      .then(unwrapResult)
      .then((thisResMoveRequest:any)=>{
        setCanonicalId(moveRequest.canonical_id);
        dispatch<any>(getInventoryVolume(moveRequest.canonical_id))
        .then(unwrapResult)
        .then(async (res:any)=>{
          const item_payment =  getPriceOfItemsOnly(res);
          let new_total = 0;
          new_total = res.move_payment - res.price_of_items
          new_total = new_total + item_payment
          new_total = new_total - previous_bulky_fee;
          let newBody;
          const bulky_fee = await calculateBulkyFee(res)
            if(thisResMoveRequest.delivery_details.packagaing_required!==false &&thisResMoveRequest.delivery_details.packaging!==null){
                new_total = new_total - Number(thisResMoveRequest.delivery_details.packaging.packaging_payment);
                new_total = new_total + Number(res.total_cubic_feet) + bulky_fee;
                newBody = {
                    id: thisMoveRequestId,
                    price_of_items: Number(Math.ceil(item_payment)),
                    move_payment: Math.ceil(Number(new_total)),
                    delivery_details: {
                        packagaing_required: true,
                        packaging: {
                            packaging_type: "Full Package",
                            dish_boxes: 0,
                            wardrobe_boxes: 0,
                            med_boxes: 0,
                            large_boxes: 0,
                            book_boxes: 0,
                            small_picture_boxes: 0,
                            medium_picture_boxes: 0,
                            large_picture_boxes: 0,
                            extra_large_picture_boxes: 0,
                            mattress_covers: 0,
                            packing_tapes: 0,
                            tv_boxes: 0,
                            packaging_payment: Math.ceil(res.total_cubic_feet),
                            custom_packaging_preference: "Boxes-and-Packing",
                        },
                    },
                  }
            }else{
                newBody = {
                    id: thisMoveRequestId,
                    price_of_items: Number(Math.ceil(item_payment)),
                    move_payment: Math.ceil(Number(new_total))
                }
            }
          dispatch<any>(updateMoveRequestById(newBody))
          .then(unwrapResult)
          .then((res:any)=>{
              setTimeout(() => {
                  setIsPageLoading(false);
                  navigate(`/contract/${moveRequest.canonical_id}`)
                }, 1500);
          }).catch((err:any)=>{
            console.log(err)
          })
        }).catch((err:any)=>{
            setIsPageLoading(false);
            console.log(err)
        })
      }).catch((err:any)=>{
        console.log(err)
        setIsPageLoading(false);
      })
    };
  
    // useEffect(() => {
    //   const totalItemsCount = roomDetailsArray.reduce((total: any, room: any) => {
    //     return total + (room.items ? room.items.length : 0);
    //   }, 0);
    //   setItemsCount(totalItemsCount);
    // }, [roomDetailsArray]);
    useEffect(() => {
      const totalItemsCount = roomDetailsArray.reduce((total: any, room: any) => {
        return total + room.room_images.reduce((imageTotal: any, image: any) => {
          return imageTotal + (image.items ? image.items.length : 0);
        }, 0);
      }, 0);
      setItemsCount(totalItemsCount);
    }, [roomDetailsArray]);
    
  
    useEffect(() => {
      fetchMoveRequestById(moveRequestId);
      setIsInputAlertModal(true);
      const action:any = localStorage.getItem('action-type')
      setActionType(action);
      console.log("Action in edit page", action);

      if(action==="edit"){
        setAllowEditing(true)
      }
    }, []);

    const [allowEditing, setAllowEditing] = useState(false)
  const navigate = useNavigate();
  const [priceOfItemsOnly, setPriceOfItemsOnly] = useState(0.0)

  useEffect(() => {
    const socket = io(thisUrl);

    socket.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    socket.on("connect_error", (error) => {
      console.error("WebSocket connection error:", error);
    });

    socket.on("notifyToRedirect", (requestData: any) => {
      setShouldRedirect(requestData);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (shouldRedirect === true) {
      window.location.reload();
    }
  }, [shouldRedirect]);

  const handleAddMoreImages = () => {
    setActionType("edit")
    setUploadMoreImagesModalVisile(true);
  }

  const localMoveFormula = (move:any) => {
    const weight = move.total_cubic_feet * 7;
    let numberOfMovers = Math.round(weight/3000)
    if(numberOfMovers<2){
      numberOfMovers = 2;
    }
    const timeToLoad = weight/1250
    const timeToUnload = timeToLoad*0.75

    let travelTime = 1;
    if(move.move_distance>50){
      travelTime = 2;
    }
    const breakTime = 1;
    const totalHours = timeToLoad + timeToUnload + travelTime + breakTime
    const hoursRequiredPerMover = totalHours/numberOfMovers
    let costPerHour=0;
    if (numberOfMovers === 2) {
      costPerHour = 169;
    } else if (numberOfMovers === 3) {
      costPerHour = 229;
    } else if (numberOfMovers === 4) {
      costPerHour = 279;
    } else if (numberOfMovers > 4) {
      costPerHour = 279 + (numberOfMovers - 4) * 50;
    }
    const localMoveCost = Math.ceil(hoursRequiredPerMover*costPerHour)

    return localMoveCost
  }
  const getPriceOfItemsOnly = (moveRequest:any) => {
    let price_of_items = 0;
    if(Number(moveRequest.move_distance) > 0 &&
    Number(moveRequest.move_distance) <= 50){
        const price_per_cubic_feet = 1.53;
        const price = localMoveFormula(moveRequest)
        price_of_items += price;
        setPriceOfItemsOnly((total) => total + price);
    }else if(
        Number(moveRequest.move_distance) > 50 &&
      Number(moveRequest.move_distance) <= 100
    ){
        const price_per_cubic_feet = 2.82;
        const price = localMoveFormula(moveRequest)
        price_of_items += price;
        setPriceOfItemsOnly((total) => total + price);
    }else if(Number(moveRequest.move_distance) > 100 &&
    Number(moveRequest.move_distance) <= 250){
        const price_per_cubic_feet = 5.12;
        const price = moveRequest.total_cubic_feet * price_per_cubic_feet * 1.1;
        price_of_items += price;
        setPriceOfItemsOnly((total) => total + price);
    }else if(Number(moveRequest.move_distance) > 250 &&
    Number(moveRequest.move_distance) <= 500){
        const price_per_cubic_feet = 6;
      const price = moveRequest.total_cubic_feet * price_per_cubic_feet * 1.1;
      price_of_items += price;
      setPriceOfItemsOnly((total) => total + price);
    }else if(Number(moveRequest.move_distance) > 500 &&
    Number(moveRequest.move_distance) <= 1500){
        const price_per_cubic_feet = 6.75;
        const price = moveRequest.total_cubic_feet * price_per_cubic_feet * 1.1;
        price_of_items += price;
        setPriceOfItemsOnly((total) => total + price);
    }else if(Number(moveRequest.move_distance) > 1500 &&
    Number(moveRequest.move_distance) <= 2250){
        const price_per_cubic_feet = 8;
        const price = moveRequest.total_cubic_feet * price_per_cubic_feet * 1.1;
        price_of_items += price;
        setPriceOfItemsOnly((total) => total + price);
    }else if(Number(moveRequest.move_distance) > 2250 &&
    Number(moveRequest.move_distance) <= 3500){
        const price_per_cubic_feet = 9;
        const price = moveRequest.total_cubic_feet * price_per_cubic_feet * 1.1;
        price_of_items += price;
        setPriceOfItemsOnly((total) => total + price);
    }
    return price_of_items;
  }
    return (
      <>
        <NavBar moveRequestId={moveRequestId} />
        <Grid container>
          {isInputAlertModal && (
            <InputAlertModal
              isInputAlertModal={isInputAlertModal}
              setIsInputAlertModal={setIsInputAlertModal}
            />
          )}
  
          <Grid item xs={12} md={5} p={2} marginX={"auto"}>
            <ProgressBar value={80} />
            <Button
                  onClick={() => {
                    navigate(-1)
                  }}
                  sx={{ marginTop: 1, marginLeft: 1 }}
                >
                  <ArrowBackIcon sx={{ color: theme.palette.primary.main }} fontSize="large" />
                </Button>
            <Stack
              mt={2}
              justifyContent={"flex-end"}
              direction={"row"}
              alignItems={"center"}
            >
                <Typography fontSize={12}>Please click the 'Edit' button at the end of the page to make any changes to the inventory.</Typography>

              <Box alignItems={"center"} alignSelf={"center"}>
                <Button
                  onClick={() => {
                    setIsInputAlertModal(true);
                  }}
                >
                  <InfoIcon
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: 30,
                      "&:hover": {
                        color: "#000000",
                        fontSize: 30,
                      },
                    }}
                  />
                </Button>
              </Box>
            </Stack>
            <Typography fontSize={13} fontWeight={600}>Note: If you make any changes to inventory, you will need to sign the contract again.</Typography>
            {
              actionType === "edit" && (
                <Box p={2} mt={2} sx={{borderRadius:2,backgroundColor: '#46A577'}}>
                  <Typography fontSize={16} fontWeight={600} color={'#FFFFFF'}>Inventory for new images added successfully. Click on Proceed button to continue.</Typography>
                </Box>
              )
            }
        
            {/* {roomDetailsArray && roomDetailsArray.length > 0 ? (
              roomDetailsArray.map((room: any, index: any) => (
                <Box key={room.id}>
                  <Box display={"flex"} justifyContent={"space-between"} mt={3}>
                    <Typography>
                      Inventory from{" "}
                      <Typography fontWeight={600}>{room.title}</Typography>
                    </Typography>
                    <Button
                      size="medium"
                      onClick={() => {
                        handleAddItemModal(room.id);
                      }}
                      sx={addButton}
                    >
                      Add Item
                    </Button>
                  </Box>
                  <TableContainer
                    sx={{
                      mt: 1,
                    }}
                  >
                    <Table sx={{ maxWidth: "100vh" }} aria-label="item table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Item Name</StyledTableCell>
                          <StyledTableCell align="center">Width</StyledTableCell>
                          <StyledTableCell align="center">Length</StyledTableCell>
                          <StyledTableCell align="center">Height</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {room.items.map((item: any, index: any) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <StyledTableCell component="th" scope="row">
                              <Stack
                                alignItems={"center"}
                                spacing={1}
                                direction={"row"}
                              >
                                <IconButton
                                  size="small"
                                  onClick={() => handleSubtract(room.id, index)}
                                  sx={iconButtonStyle}
                                >
                                  <Subtract sx={{ width: 15 }} />
                                </IconButton>
                                <Typography>{item.item_name}</Typography>
  
                                <IconButton
                                  size="small"
                                  sx={iconButtonStyle}
                                  onClick={() =>
                                    handleAddItemModal(room.id, item, index)
                                  }
                                >
                                  <EditIcon sx={{ width: 11 }} />
                                </IconButton>
                              </Stack>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {item.item_width}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {item.item_height}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {item.item_length}
                            </StyledTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              ))
            ) : (
              <Typography textAlign={"center"} fontWeight={600} color={"#949494"}>
                NO ITEMS
              </Typography>
            )} */}
            {roomDetailsArray && roomDetailsArray.length > 0 ? (
    roomDetailsArray.map((room: any, roomIndex: any) => (
      <Box key={room.id}>
     
        {room.room_images && room.room_images.length > 0 ? (
          room.room_images.map((image: any, imageIndex: any) => (
            <Box key={image.id} mt={2}>
 <Stack alignItems={'flex-end'} justifyContent={'space-between'} direction={'row'}>
              <Stack spacing={0.5}>
             <Typography>
          Inventory from: <Typography fontWeight={600}>{room.title}</Typography>
        </Typography>
            <Typography fontWeight={600}>Image: {imageIndex+1}</Typography>
            {allowEditing===true&&(
                  <Button
                  size="medium"
                  onClick={() => {
                      handleAddItemModal(room.id, image.id, null);
                    }}
                    sx={addButton}
                    >
                Add Item
              </Button>
            )}
            </Stack>
        <img src={image.image_url} style={{borderRadius:4, border: '1px solid black'}} width={120} height={120} alt="" />
          </Stack>
              <TableContainer
                sx={{
                  mt: 1,
                }}
              >
                <Table sx={{ maxWidth: "100vh" }} aria-label="item table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Item Name</StyledTableCell>
                      <StyledTableCell align="center">Width</StyledTableCell>
                      <StyledTableCell align="center">Length</StyledTableCell>
                      <StyledTableCell align="center">Height</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {image.items && image.items.length > 0 ? (
                      image.items.map((item: any, itemIndex: any) => (
                        <TableRow
                          key={itemIndex}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell component="th" scope="row">
                            <Stack
                              alignItems={"center"}
                              spacing={1}
                              direction={"row"}
                            >
                                {allowEditing&&(
                                    <IconButton
                                    size="small"
                                    onClick={() => handleSubtract(room.id, image.id, itemIndex)}
                                    sx={iconButtonStyle}
                                    >
                                <Subtract sx={{ width: 15 }} />
                              </IconButton>
                            )}
                              <Typography>{item.item_name}</Typography>
                              {allowEditing&&(
                                  <IconButton
                                  size="small"
                                  sx={iconButtonStyle}
                                  onClick={() =>
                                    handleAddItemModal(room.id, image.id, item, itemIndex)
                                }
                                >
                                <EditIcon sx={{ width: 11 }} />
                              </IconButton>
                            )}
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.item_width}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.item_height}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.item_length}
                          </StyledTableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <StyledTableCell colSpan={4} align="center">
                          No items
                        </StyledTableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Divider/>
            </Box>
          ))
        ) : (
          <Typography textAlign={"center"} fontWeight={600} color={"#949494"}>
            No images
          </Typography>
        )}
      </Box>
    ))
  ) : (
    <Typography textAlign={"center"} fontWeight={600} color={"#949494"}>
      NO ITEMS
    </Typography>
  )}
  
  
            <Box
              mt={2}
              sx={{
                p: 2,
                backgroundColor: "#5A7BFC14",
                border: "1px solid #5A7BFC59",
                borderRadius: "8px",
              }}
            >
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography fontSize={14} fontWeight={550} color={"#3C3C3C"}>
                  Total Items:
                </Typography>
                <Typography fontSize={14} fontWeight={550} color={'#000000'}>
                  {itemsCount}
                </Typography>
              </Stack>
            </Box>
            <Box
              mt={2}
              sx={{
                p: 2,
                backgroundColor: "#5A7BFC14",
                border: "1px solid #5A7BFC59",
                borderRadius: "8px",
              }}
            >
              {allowEditing&&(
                <Typography fontSize={13} fontWeight={600} color={'#000000'}>Tap + or - to add or remove boxes.</Typography>
              )}
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography fontSize={14} fontWeight={550} color={"#3C3C3C"}>
                  Total boxes anticipated for boxable items:
                </Typography>
                <Stack alignItems={'center'} spacing={1} direction={'row'}>
                <Typography fontSize={14} fontWeight={550} color={'#000000'}>
                  {numberOfBoxexRequired}
                </Typography>
                {allowEditing&&(
                    <IconButton
                    size="small"
                    onClick={() =>  setNumberOfBoxexRequired((prevNumber:any) => prevNumber + 1)}
                    sx={iconButtonStyle}
                    >
                     <AddCircleIcon sx={{ width: 15 }} />
                 </IconButton>
                    )}
                    {allowEditing&&(
                        <IconButton
                        size="small"
                        onClick={() =>  { if(numberOfBoxexRequired!==0){
                          setNumberOfBoxexRequired((prevNumber:any) => prevNumber - 1)
                        }
                        }}
                        sx={iconButtonStyle}
                        >
                     <RemoveCircleIcon sx={{ width: 15 }} />
                 </IconButton>
                    )}
                </Stack>
              </Stack>
            </Box>

            <Box display={"flex"} justifyContent={"end"} mt={3}>
            <Button
                        onClick={() => {
                            handleAddMoreImages();
                        }}
                        sx={blueButton}
                        >
                Add More Images +
              </Button>
              </Box> 
  
            <Box display={"flex"} justifyContent={"end"} mt={3}>
              {
                actionType !== "edit" && (
                <>
                  <Button sx={{color:'#000000', border:'1px solid black'}} onClick={()=>{
                      navigate(-1)
                  }} variant="outlined">Cancel</Button>

                  <Button onClick={()=>{
                    setAllowEditing(!allowEditing)
                }} sx={{marginX:2, color:'#000000', border:'1px solid black'}} variant="outlined">
                    Edit
                <EditOutlinedIcon fontSize="small"/>
                    </Button>
                </>

                )
               }
                   
                    {allowEditing&&(

                        <Button
                        onClick={() => {
                            updateThisMoveRequest();
                        }}
                        sx={blueButton}
                        >
                Proceed
              </Button>
            )}
            </Box>
  
            {/* {isAddItemModal && (
              <AddItemModal
                isAddItemModal={isAddItemModal}
                setIsAddItemModal={setIsAddItemModal}
                editItemInfo={editItemInfo}
                setEditItemInfo={setEditItemInfo}
                roomId={roomId}
                itemIndex={itemIndex}
                roomDetailsArray={roomDetailsArray}
                setRoomDetailsArray={setRoomDetailsArray}
              />
            )} */}
            {isAddItemModal && (
    <AddItemModal
      isAddItemModal={isAddItemModal}
      setIsAddItemModal={setIsAddItemModal}
      editItemInfo={editItemInfo}
      setEditItemInfo={setEditItemInfo}
      roomId={roomId}
      imageId={imageId}
      itemIndex={itemIndex}
      roomDetailsArray={roomDetailsArray}
      setRoomDetailsArray={setRoomDetailsArray}
    />
  )}
  
  
            {isPackaginServiceModal && (
              <PackagingServiceModal
                thisMoveRequestId={thisMoveRequestId}
                moveRequestId={canonicalId}
                isPackaginServiceModal={isPackaginServiceModal}
                setIsPackaginServiceModal={setIsPackaginServiceModal}
              />
            )}

          {uploadMoreImagesModalVisile && (
            <UploadMoreImagesModal
            uploadMoreImagesModalVisile={uploadMoreImagesModalVisile}
            setUploadMoreImagesModalVisile={setUploadMoreImagesModalVisile}
            actionType={actionType}
            moveRequest={moveRequest}
            />
          )}
  
            {isPageLoading && (
              <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isPageLoading}
                onClick={() => {
                  setIsPageLoading(true);
                }}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          </Grid>
        </Grid>
      </>
    );
  };
  
  export default ModifyItems;
  