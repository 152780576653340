import { createTheme } from "@mui/material";

const currentTenant = process.env.REACT_APP_TENANT || "";
console.log("REACT_APP_TENANT>>:", process.env.REACT_APP_TENANT);

let primaryColor = "#5858E0";

if (currentTenant === "wemove" || currentTenant === "sold") {
  primaryColor = "#0257CF";
} else if (currentTenant === "turbotenant") {
  primaryColor = "#033a6d";
} else if (currentTenant === "movebees") {
  primaryColor = "#dbb20d";
} else if (currentTenant === "krupp") {
  primaryColor = "#01565E";
} else if (currentTenant === "summitmoving") {
  primaryColor = "#C4161C";
} else if (currentTenant === "rockstarspromoverspartial") {
  primaryColor = "#ff0000";
} else if (currentTenant === "rockstarspromoverscomplete") {
  primaryColor = "#ff0000";
} else if (currentTenant === "dearmanmoving") {
  primaryColor = "#3854A4";
}

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
      light: "#F2F4FF",
      dark: "#6552FF",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#19857b",
    },
  },
});

export default theme;
