import {
  Stack,
  Typography,
  Box,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import theme from "../theme/theme";

// Custom component to show the progress as a fraction
export const CustomCircularProgress = ({ value, total }: any) => {
  const percentage = (value / total) * 100;
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        width: isMobile ? "40px" : "60px", // Ensure fixed width on mobile
        height: isMobile ? "40px" : "60px", // Ensure fixed height on mobile
        overflow: "hidden", // Prevent any overflow
        marginRight: isMobile ? "auto" : undefined, // Centering adjustments for mobile
        marginLeft: isMobile ? "auto" : undefined, // Centering adjustments for mobile
      }}
    >
      {/* Background Circle */}
      <CircularProgress
        variant="determinate"
        value={100}
        thickness={6}
        size={isMobile ? 40 : 60} // Adjusted size to match the layout
        sx={{
          color: "#e0e0e0", // Light gray background color
          position: "absolute",
          zIndex: 0,
        }}
      />
      {/* Progress Circle */}
      <CircularProgress
        variant="determinate"
        value={percentage}
        thickness={6}
        size={isMobile ? 40 : 60} // Adjusted size to match the layout
        sx={{
          color: `${theme.palette.primary.main}`, // Blue color for the progress
          position: "absolute",
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          color="primary"
          sx={{ fontWeight: 600, fontSize: isMobile ? 10 : 16 }} // Adjusted font size
        >
          {`${value}/${total}`}
        </Typography>
      </Box>
    </Box>
  );
};
