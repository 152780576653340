import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Stairs from "../../assets/images/StairsIcon.png";
import Elevator from "../../assets/images/Elevator.png";
import NotAvailable from "../../assets/images/N:A-Icon.png";
import SecurityIcon from "@mui/icons-material/Security";
import {
  Alert,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import placesApiKey from "../utils/Google-Places-API-Key";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CustomCircularProgress } from "../circularProgress";
import { NeedHelp } from "../needHelp";

const PLACES_API_KEY = placesApiKey;

const marks = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 10,
  },
  {
    value: 20,
  },
  {
    value: 30,
  },
  {
    value: 40,
  },
  {
    value: 50,
  },
  {
    value: 60,
  },
  {
    value: 70,
  },
  {
    value: 80,
  },
  {
    value: 90,
  },
  {
    value: 100,
    label: "100%",
  },
];

const storageSizeDropDown = [
  {
    label: "5x5x8",
    value: 200,
  },
  {
    label: "5x10x8",
    value: 400,
  },
  {
    label: "5x15x8",
    value: 600,
  },
  {
    label: "10x10x8",
    value: 800,
  },
  {
    label: "10x15x8",
    value: 1200,
  },
  {
    label: "10x20x8",
    value: 1600,
  },
  {
    label: "10x25x8",
    value: 2000,
  },
  {
    label: "10x30x8",
    value: 2400,
  },
  {
    label: "20x20x8",
    value: 3200,
  },
];

const HomeStorageCombo = ({
  isHomeStorageComboModal,
  setIsHomeStorageComboModal,
  createMoveRequest,
  thisMoveRequest,
  isLoading,
  setIsLoading,
  setMoveType,
  setOutOfStateMoveModalVisible,
}: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const notSeelectedButtonStyle = {
    width: 150,
    height: 120,
    backgroundColor: "#F2F2F4 !important",
    color: "#262626",
    fontSize: 12,
    fontWeight: 550,
    border: "1px solid #0000001A",
    borderRadius: "16px",
  };

  const selectedButtonStyle = {
    width: 150,
    height: 120,
    backgroundColor: `#002496 !important`,
    color: "#FDFCFD",
    fontSize: 12,
    fontWeight: 550,
    borderRadius: "16px",
  };
  const [homeAddress, setHomeAddress] = useState("");
  const [storageSize, setStorageSize] = useState<any>("");
  const [storageAddress, setStorageAddress] = useState("");
  const [storageZipCode, setStorageZipCode] = useState("");

  const [storageDestinationFloor, setStorageDestinationFloor] =
    useState<any>(null);
  const [isStorageElevator, setIsStorageElevator] = useState("");
  const [storageFilled, setStorageFilled] = useState<any>(70);

  const [formError, setFormError] = useState("");

  const handleHomeStorageCombo = () => setIsHomeStorageComboModal(true);
  const handleClose = () => setIsHomeStorageComboModal(true);

  const valueText = (value: number) => {
    return `${value}%`;
  };

  const handleStorageFilled = (event: any) => {
    setStorageFilled(event.target.value);
  };

  const handleZipCodeChange = (event: any) => {
    let inputValue = event.target.value.replace(/[^0-9]/g, "");
    const maxLength = 5;
    if (inputValue.length > maxLength) {
      inputValue = inputValue.substring(0, maxLength);
    }
    setStorageZipCode(inputValue);
    event.target.value = inputValue;
  };

  const handleInputNumber = (event: any, setNumber: any) => {
    let inputValue = event.target.value.replace(/[^0-9]/g, "");
    const maxLength = 7;
    if (inputValue.length > maxLength) {
      inputValue = inputValue.substring(0, maxLength);
    }
    setNumber(inputValue);
    event.target.value = inputValue;
  };

  const navigateToLocationInfo = async () => {
    if (homeAddress === "") {
      setFormError("Please enter your Home Address.");
      return;
    }

    if (storageSize === "") {
      setFormError("Please select a Storage size.");
      return;
    }

    if (storageAddress === "") {
      setFormError("Please enter your Storage Address.");
      return;
    }

    if (storageZipCode === "") {
      setFormError("Please enter your Storage Zip code.");
      return;
    }

    if (isStorageElevator === "") {
      setFormError(
        "Please select if Elevator or Stairs would be needed for Storage."
      );
      return;
    }

    if (storageDestinationFloor === null) {
      setFormError("Please enter Storage floor number.");
      return;
    }

    setFormError("");
    await createMoveRequest({
      id: thisMoveRequest.id,
      combo_home_storage: {
        home_address: homeAddress,
        storage: {
          storage_size: Number(storageSize.target.value.value),
          storage_filled: Number(storageFilled),
          zip_code: storageZipCode,
          address: storageAddress,
          floor_no:
            isStorageElevator === "not-applicable"
              ? 0
              : Number(storageDestinationFloor),
          is_elevator_accessible:
            isStorageElevator === "elevator" ? true : false,
          are_stairs_present: isStorageElevator === "stairs" ? true : false,
        },
      },
    });
  };

  const fetchZipCodeForAddress = async (add: string) => {
    const apiKey = "AIzaSyAk8hBHGztX6M9UX2MdZlRQS3HbdqINzp8";
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${add}&key=${apiKey}`;

    try {
      const response = await axios.get(url);
      const results = response.data.results;

      const zipCode = results[0].address_components?.find((component: any) =>
        component.types.includes("postal_code")
      )?.long_name;
      if (zipCode) {
        return zipCode.toString();
      } else {
        return "";
      }
    } catch (error) {
      throw error;
    }
  };

  const handleAddress = async (add: any) => {
    const zip = await fetchZipCodeForAddress(add.value.description);
    if (zip) {
      const completeAddress = add.value.description.toString() + ", " + zip;
      setHomeAddress(completeAddress);
    } else {
      const completeAddress = add.value.description.toString();
      setHomeAddress(completeAddress);
    }
  };

  const handleStorageAddress = async (add: any) => {
    const zip = await fetchZipCodeForAddress(add.value.description);
    const completeAddress = add.value.description.toString() + ", " + zip;
    setStorageZipCode(zip);
    setStorageAddress(completeAddress);
  };

  return (
    <Box
      sx={{
        width: { xs: "90vw", sm: "80vw", md: "70vw", lg: "60vw" },
        height: isMobile ? "90vh" : "auto",
        p: { xs: 2, sm: 4 },
        mt: { xs: 3, sm: 5 },
        border: "1px solid #0000001A",
        borderRadius: "8px",
        overflow: "auto",
        backgroundColor: "#FBFBFB",
      }}
    >
      <NeedHelp />
      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
          onClick={() => {
            setIsLoading(true);
          }}
        >
          <Stack spacing={2} alignItems={"center"} justifyContent={"center"}>
            <CircularProgress color="inherit" />
          </Stack>
        </Backdrop>
      )}

      <Grid container direction={"row"} spacing={2}>
        {/* Header Section */}
        <Grid item xs={12}>
          <Stack
            sx={{
              alignItems: isMobile ? "center" : "flex-start",
              overflow: "auto",
              mb: 3,
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Typography fontSize={isMobile ? 18 : 24} fontWeight={600}>
                Combo Home-Storage
              </Typography>
              <CustomCircularProgress value={3} total={10} />
            </Stack>
            <Stack>
              <Typography fontSize={isMobile ? 18 : 24} fontWeight={500}>
                Home Address
              </Typography>
              <Typography fontSize={isMobile ? 10 : 16} color={"#646464"}>
                Fill in all info correctly
              </Typography>
            </Stack>
          </Stack>
        </Grid>

        {/* Address Input Section */}
        <Grid item xs={12} sm={6}>
          <Typography mt={1} fontSize={14} fontWeight={500} color={"#262626"}>
            What address are you moving from?
          </Typography>

          <GooglePlacesAutocomplete
            apiOptions={{
              language: "en",
            }}
            minLengthAutocomplete={1}
            apiKey={PLACES_API_KEY}
            debounce={1000}
            selectProps={{
              styles: {
                input: (provided) => ({
                  ...provided,
                  color: "#000000",
                  height: "100%",
                  borderRadius: "8px",
                }),
                option: (provided) => ({
                  ...provided,
                  color: "#808080",
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "#808080",
                }),
              },
              onChange: handleAddress,
              placeholder: "Enter Address",
            }}
          />
        </Grid>

        {/* Storage Info Section */}

        {/* Storage Fullness Section */}
        <Grid item xs={12}>
          <Grid item xs={12} sm={5} mt={2}>
            <Stack mt={5}>
              <Typography fontSize={isMobile ? 18 : 24} fontWeight={500}>
                Storage Address
              </Typography>
              <Typography fontSize={isMobile ? 10 : 16} color={"#646464"}>
                Fill in all info correctly
              </Typography>
            </Stack>

            <Stack
              alignItems={"center"}
              marginTop={1}
              direction={"row"}
              spacing={1}
            >
              <FormControl sx={{ minWidth: 150 }} size="small">
                <InputLabel id="demo-select-small-label">
                  {storageSize !== ""
                    ? storageSize.target.value.label
                    : "Storage Size"}
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={""}
                  label="Storage Size"
                  onChange={(e) => {
                    setStorageSize(e);
                  }}
                  required
                >
                  {storageSizeDropDown.map((size: any) => {
                    return (
                      <MenuItem key={size.value} value={size}>
                        {size.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          <Typography mt={2} fontSize={14}>
            How full is your storage unit?
          </Typography>
          <Typography fontStyle={"italic"} fontSize={12} color={"#262626"}>
            drag to use
          </Typography>

          <Slider
            sx={{ mt: 2, ml: 1, width: isMobile ? 230 : 450 }}
            aria-label="Always visible"
            defaultValue={70}
            getAriaValueText={valueText}
            step={10}
            marks={marks}
            onChange={(val) => {
              handleStorageFilled(val);
            }}
            valueLabelDisplay="on"
          />
        </Grid>

        {/* Storage Facility Address Section */}
        <Grid item xs={12} sm={6}>
          <Typography mt={2} fontSize={14} fontWeight={500}>
            What is the storage facility address?
          </Typography>

          <Stack
            spacing={1}
            marginTop={2}
            direction={isMobile ? "column" : "row"}
          >
            <GooglePlacesAutocomplete
              apiOptions={{
                language: "en",
              }}
              minLengthAutocomplete={1}
              apiKey={PLACES_API_KEY}
              debounce={1000}
              selectProps={{
                styles: {
                  input: (provided) => ({
                    ...provided,
                    color: "#000000",
                    height: "100%",
                    width: 150,
                    borderRadius: "8px",
                  }),
                  option: (provided) => ({
                    ...provided,
                    color: "#808080",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "#808080",
                  }),
                },
                onChange: handleStorageAddress,
                placeholder: "Enter Address",
              }}
            />

            <TextField
              variant="outlined"
              type="text"
              label="Zip Code"
              size="small"
              value={storageZipCode}
              onChange={(e) => {
                handleZipCodeChange(e);
              }}
              inputProps={{
                style: {
                  background: "#5A7BFC14",
                  borderRadius: "8px",
                },
              }}
              sx={{
                marginTop: 1,
                width: 220,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#5A7BFC59",
                    borderWidth: "1.43",
                    borderRadius: "8px",
                  },
                },
              }}
              required
            />
          </Stack>
        </Grid>

        {/* Elevator or Stairs Section */}
        <Grid item xs={12}>
          <Typography mt={2} fontWeight={400} fontSize={14}>
            Are Stairs or Elevator needed?
          </Typography>

          <Typography
            fontWeight={400}
            fontSize={14}
            sx={{ fontStyle: "italic" }}
          >
            choose one
          </Typography>

          <Stack mt={2} direction="row" spacing={1}>
            <Button
              onClick={() => {
                setIsStorageElevator("stairs");
                setStorageDestinationFloor(null);
              }}
              sx={
                isStorageElevator === "stairs"
                  ? selectedButtonStyle
                  : notSeelectedButtonStyle
              }
            >
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                spacing={1}
              >
                <img
                  src={Stairs}
                  style={{
                    filter:
                      isStorageElevator === "stairs"
                        ? "none"
                        : "invert(100%) brightness(2)",
                    width: 50,
                    height: 50,
                  }}
                  alt="stairs"
                />
                <Typography
                  fontSize={12}
                  color={isStorageElevator === "stairs" ? "#FFFFFF" : "#000000"}
                >
                  Stairs
                </Typography>
              </Stack>
            </Button>

            <Button
              onClick={() => {
                setIsStorageElevator("elevator");
                setStorageDestinationFloor(null);
              }}
              sx={
                isStorageElevator === "elevator"
                  ? selectedButtonStyle
                  : notSeelectedButtonStyle
              }
            >
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                spacing={1}
              >
                <img
                  src={Elevator}
                  style={{
                    filter:
                      isStorageElevator === "elevator"
                        ? "invert(100%) brightness(2)"
                        : "none",
                    width: 50,
                    height: 50,
                  }}
                  alt="elevator"
                />
                <Typography
                  fontSize={12}
                  color={
                    isStorageElevator === "elevator" ? "#FFFFFF" : "#000000"
                  }
                >
                  Elevator
                </Typography>
              </Stack>
            </Button>

            <Button
              onClick={() => {
                setIsStorageElevator("not-applicable");
                setStorageDestinationFloor(0);
              }}
              sx={
                isStorageElevator === "not-applicable"
                  ? selectedButtonStyle
                  : notSeelectedButtonStyle
              }
            >
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                spacing={1}
              >
                <img
                  src={NotAvailable}
                  style={{
                    filter:
                      isStorageElevator === "not-applicable"
                        ? "invert(100%) brightness(2)"
                        : "none",
                    width: 50,
                    height: 50,
                  }}
                  alt="not-applicable"
                />
                <Typography
                  fontSize={12}
                  color={
                    isStorageElevator === "not-applicable"
                      ? "#FFFFFF"
                      : "#000000"
                  }
                >
                  Not Applicable
                </Typography>
              </Stack>
            </Button>
          </Stack>

          {isStorageElevator !== "not-applicable" && (
            <>
              <Typography
                fontSize={14}
                mt={2}
                fontWeight={400}
                color={"#262626"}
              >
                WHAT FLOOR IS YOUR STORAGE ON ?
              </Typography>

              <TextField
                variant="outlined"
                value={storageDestinationFloor || ""}
                size="small"
                onChange={(event) => {
                  handleInputNumber(event, setStorageDestinationFloor);
                }}
                inputProps={{
                  style: {
                    background: "#5A7BFC14",
                    borderRadius: "8px",
                  },
                }}
                sx={{
                  marginTop: 1,
                  width: 220,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#5A7BFC59",
                      borderWidth: "1.43",
                      borderRadius: "8px",
                    },
                  },
                }}
              />
            </>
          )}
        </Grid>
      </Grid>
      <Stack
        p={1}
        spacing={1}
        mt={5}
        width={"90%"}
        direction={"row"}
        alignItems={"center"}
        sx={{ backgroundColor: "#CDECE8", borderRadius: 4 }}
      >
        <SecurityIcon fontSize="small" sx={{ color: "#0FB69D" }} />
        <Typography fontSize={10}>
          Your info is safe with us. We need details to create the perfect plan
          for you.
        </Typography>
      </Stack>

      <Box p={3}>
        {formError && <Alert severity="error">{formError}</Alert>}
      </Box>

      <Stack p={4} direction="row" spacing={2} justifyContent="center">
        <Button
          onClick={() => {
            setMoveType("");
            setOutOfStateMoveModalVisible(true);
            setIsHomeStorageComboModal(false);
          }}
          sx={{
            width: 120,
            height: 45,
            backgroundColor: "#E9E9EA",
            color: "#808080",
            fontSize: 12,
            fontWeight: 550,
          }}
        >
          Back
        </Button>
        <Button
          onClick={navigateToLocationInfo}
          sx={{
            width: 180,
            height: 45,
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: "#FFFFFF",
            fontSize: 12,
            fontWeight: 550,
          }}
        >
          Continue
        </Button>
      </Stack>
    </Box>
  );
};

export default HomeStorageCombo;
